<template>
  <custom-card title="カメラ設定">
    <Form @submit="handleUpdate" ref="groupBasicForm">
      <div class="row mb-2">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          カメラ名
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="name"
            type="text"
            :value="group.name"
            @input="detectInputChange"
            class="form-control text-center"
            rules="required|maxLength:50"
          />
          <ErrorMessage name="name" class="error-feedback" />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          人数
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="count"
            type="number"
            :value="group.count"
            @input="detectInputChange"
            class="form-control text-center"
            :rules="nonNegativeIntegerRule"
          />
          <ErrorMessage name="count" class="error-feedback" />
        </div>
      </div>
      <div class="row">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          利用時間外に人数更新の停止
        </label>
        <div
          class="col-8 col-md-4 d-flex align-items-center justify-content-center"
        >
          <Field
            name="is_stop_update_count_when_ots"
            type="checkbox"
            @input="detectInputChange"
            class="form-check-input align-middle"
            value="true"
          />
          <ErrorMessage
            name="is_stop_update_count_when_ots"
            class="error-feedback"
          />
        </div>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <back-or-submit
        :canSubmit="canSubmit"
        :loading="loading"
        :to="{ name: 'location', params: { location_key: locationKey } }"
      ></back-or-submit>
    </Form>
  </custom-card>
</template>

<script>
import GroupService from "../services/group.service";
import formMixin from "../mixins/formMixin";
import inputRuleMixin from "../mixins/inputRuleMixin";
import CustomCard from "./CustomCard.vue";
export default {
  name: "GroupBasicSetting",
  props: ["group", "locationKey"],
  emits: ["updateSucceed", "update:group"],
  mixins: [formMixin, inputRuleMixin],
  components: { CustomCard },
  mounted() {
    this.$refs.groupBasicForm.setFieldValue(
      "is_stop_update_count_when_ots",
      this.group.is_stop_update_count_when_ots.toString()
    );
  },
  methods: {
    setGroup(value) {
      this.$emit("update:group", value);
    },
    handleUpdate(group) {
      this.beforeSubmit();
      group.is_stop_update_count_when_ots =
        group.is_stop_update_count_when_ots === "true";
      GroupService.patch(this.group.key, group).then(
        (response) => {
          if (response.status == 200) {
            this.setGroup(response.data);
            this.clearFormState();
            this.$emit("updateSucceed");
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
    },
  },
};
</script>
