import axios from "axios";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/public/";

class GroupPublicService {
    getRecentSummary(corp_id, key) {
        return axios.get(`${API_URL}corp_${corp_id}/group/${key}/recent_summary/`)
    }
}

export default new GroupPublicService();