<template>
  <div class="container p-1 text-center">
    <button
      type="button"
      class="btn-lg btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#qrCodeModal"
    >
      QRコード作成
    </button>
  </div>
  <div
    class="modal fade"
    id="qrCodeModal"
    tabindex="-1"
    aria-labelledby="qrCodeModalLable"
    aria-hidden="true"
  >
    <div class="modal-dialog text-center">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title w-100" id="qrCodeModalLable">
            【{{ qrCodeName }}】のQRコード
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ URL }}</p>
          <qrcode-vue :value="URL" :size="qrCodeSize"></qrcode-vue>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  name: "QrCodeModal",
  props: ["URL", "qrCodeSize", "qrCodeName"],
  components: {
    QrcodeVue,
  },
};
</script>