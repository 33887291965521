<template>
  <custom-card title="利用時間設定">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="table-secondary text-center">
          <tr>
            <th></th>
            <th v-for="weekdayString in weekdayStrings" :key="weekdayString">
              {{ weekdayString[0] }}
            </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr>
            <td>利用時間</td>
            <td v-for="num in 7" :key="num" class="align-middle">
              <button
                class="btn btn-primary"
                :value="num - 1"
                @click="handleRender($event, 'GroupOpeningPeriodSetting')"
                :disabled="isDisabled(num - 1, 'GroupOpeningPeriodSetting')"
              >
                設定
              </button>
            </td>
          </tr>
          <tr>
            <td>リセット時間</td>
            <td v-for="num in 7" :key="num" class="align-middle">
              <button
                class="btn btn-primary"
                :value="num - 1"
                @click="handleRender($event, 'GroupResetTimingSetting')"
                :disabled="isDisabled(num - 1, 'GroupResetTimingSetting')"
              >
                設定
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <component
      v-if="componentName"
      :is="componentName"
      v-model:set="set"
      :weekday="weekday"
      @updateSucceed="handleUpdateSucceed"
    ></component>
    <hr class="my-3 mx-1" />
    <Form @submit="handleUpdate">
      <div class="row mb-2">
        <label class="d-flex justify-content-center col-4 col-form-label offset-md-3 col-md-2">
          <span class="align-self-center">利用時間外の画像</span>
        </label>
        <div class="col-8 col-md-4 text-center">
          現在:
          <a :href="group.ots_image">{{ url2FileName(group.ots_image) }}</a>
          <Field
            name="ots_image"
            type="file"
            @input="detectInputChange"
            class="form-control"
            :key="fileInputKey"
            rules="image"
          />
          <ErrorMessage name="ots_image" class="error-feedback" />
        </div>
      </div>
      <div class="row">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          利用時間外の色
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="ots_color"
            type="color"
            :value="group.ots_color"
            @input="detectInputChange"
            class="form-control text-center h-100"
            :rules="colorRule"
          />
          <ErrorMessage name="ots_color" class="error-feedback" />
        </div>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <back-or-submit
        :canSubmit="canSubmit"
        :loading="loading"
        :to="{ name: 'location', params: { location_key: locationKey } }"
      ></back-or-submit>
    </Form>
  </custom-card>
  <!-- <pre>{{ JSON.stringify(openingPeriodSet, null, 2) }}</pre> -->
</template>

<script>
import GroupOpeningPeriodSetting from "./GroupOpeningPeriodSetting.vue";
import GroupResetTimingSetting from "./GroupResetTimingSetting.vue";
import GroupService from "../services/group.service";
import formMixin from "../mixins/formMixin";
import inputRuleMixin from "../mixins/inputRuleMixin";
import CustomCard from "../components/CustomCard.vue";
export default {
  name: "GroupTimeSetting",
  props: ["openingPeriodSet", "resetTimingSet", "group", "locationKey"],

  emits: [
    "updateSucceed",
    "update:openingPeriodSet",
    "update:resetTimingSet",
    "update:group",
  ],
  mixins: [formMixin, inputRuleMixin],
  components: {
    GroupOpeningPeriodSetting,
    GroupResetTimingSetting,
    CustomCard,
  },
  data() {
    return {
      componentName: "",
      // TODO: consider using computed and watch option
      set: null,
      weekday: null,
    };
  },
  methods: {
    setGroup(value) {
      this.$emit("update:group", value);
    },
    setOpeningPeriodSet(value) {
      this.$emit("update:openingPeriodSet", value);
    },
    setResetTimingSet(value) {
      this.$emit("update:resetTimingSet", value);
    },
    handleRender(event, componentName) {
      //   // eslint-disable-next-line no-debugger
      //   debugger;
      this.set = [];
      this.componentName = componentName;
      this.weekday = parseInt(event.target.value);
      if (componentName == "GroupOpeningPeriodSetting") {
        this.set = this.openingPeriodSet.filter(
          (el) => el.weekday == this.weekday
        );
        this.set.sort((a,b) =>{
        const nameA = Number(a.start_at.replace(/:/g,''));//時間を数値に変換
        const nameB = Number(b.start_at.replace(/:/g,'')); // 大文字と小文字を無視する
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // 名前が等しい
        return 0;
      }
      );
      } else if (componentName == "GroupResetTimingSetting") {
        this.set = this.resetTimingSet.filter(
          (el) => el.weekday == this.weekday
        );
        this.set.sort((a,b) =>{
        const nameA = Number(a.timing.replace(/:/g,''));//時間を数値に変換
        const nameB = Number(b.timing.replace(/:/g,'')); // 大文字と小文字を無視する
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // 名前が等しい
        return 0;
      }
      );
      }
    },
    isDisabled(weekday, componentName) {
      return weekday == this.weekday && componentName == this.componentName;
    },
    handleUpdate(group) {
      let formData = new FormData();
      formData.append("ots_color", group["ots_color"]);
      if (group["ots_image"] != undefined) {
        formData.append("ots_image", group["ots_image"][0]);
      }
      this.beforeSubmit();
      GroupService.patch(this.group.key, formData).then(
        (response) => {
          if (response.status == 200) {
            this.setGroup(response.data);
            this.clearFormState();
            this.$emit("updateSucceed");
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
    },
    handleUpdateSucceed(dataSet, componentName) {
      // update props `openingPeriodSet` or `resetTimingSet`
      let id2Data = {};
      dataSet.forEach((el) => {
        id2Data[el["id"]] = el;
      });
      if (componentName == GroupOpeningPeriodSetting.name) {
        let newOpeningPeriodSet = [];
        this.openingPeriodSet.forEach((el) => {
          if (el["id"] in id2Data) {
            newOpeningPeriodSet.push(id2Data[el["id"]]);
          } else {
            newOpeningPeriodSet.push(el);
          }
        });
        this.setOpeningPeriodSet(newOpeningPeriodSet);
      } else if (componentName == GroupResetTimingSetting.name) {
        let newResetTimingSet = [];
        this.resetTimingSet.forEach((el) => {
          if (el["id"] in id2Data) {
            newResetTimingSet.push(id2Data[el["id"]]);
          } else {
            newResetTimingSet.push(el);
          }
        });
        this.setResetTimingSet(newResetTimingSet);
      }
      this.$emit("updateSucceed");
    },
  },
};
</script>
