<template>
  <div class="container p-1 text-center">
    <p class="mb-0" style="font-size: 130%">{{ dateString }}</p>
    <p class="mb-0" style="font-size: 250%">{{ timeString }}</p>
  </div>
</template>
<script>
export default {
  name: "DateAndTime",
  data() {
    return {
      date: null,
      weekDays: ["月", "火", "水", "木", "金", "土", "日"],
    };
  },
  computed: {
    dateString() {
      return `${this.date.getFullYear()}年${this.date.getMonth() +
        1}月${this.date.getDate()}日（${
        this.weekDays[this.date.getWeekDay()]
      }）`;
    },
    timeString() {
      const zeroPad = (num, places) => String(num).padStart(places, '0')
      return `${zeroPad(this.date.getHours(), 2)}:${zeroPad(this.date.getMinutes(), 2)}`;
    },
  },
  beforeMount() {
    setInterval(this.update(), 1e3);
  },
  methods: {
    update() {
      this.date = new Date();
    },
  },
};
</script>
