/*
    vee-validation  
    global validation
    https://vee-validate.logaretm.com/v4/guide/global-validators
*/
import { defineRule } from "vee-validate";
import { image, confirmed } from "@vee-validate/rules";

defineRule("required", (value) => {
  if (value && value.trim()) return true;
  else return "This field is required";
});

defineRule("maxLength", (value, [limit]) => {
  if (!value || value.length > limit) {
    return `This field must be at most ${limit} characters`;
  }
  return true;
});

defineRule("image", image);
defineRule("confirmed", confirmed);
