/*
Common thing used in form
- submit and back button
- state control: loading, canEdit, inputChanged
*/
import { Form, Field, ErrorMessage } from "vee-validate";
import BackOrSubmit from "../components/BackOrSubmit.vue";
import Submit from "../components/Submit.vue";
import CustomErrorMessage from "../components/CustomErrorMessage.vue";
import errorMessageMixin from "./errorMessageMixin";
const formMixin = {
  mixins: [errorMessageMixin],
  components: {
    BackOrSubmit,
    CustomErrorMessage,
    Submit,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      inputChanged: false,
      loading: false,
      message: "",
    };
  },
  computed: {
    canSubmit() {
      return this.inputChanged && !this.loading;
    },
  },
  methods: {
    detectInputChange() {
      this.inputChanged = true;
    },
    beforeSubmit() {
      this.loading = true;
    },
    clearFormState() {
      this.loading = false;
      this.message = "";
      this.inputChanged = false;
    },
  },
};

export default formMixin;
