<template>
    <div style="position:relative;height:400px;">
    <canvas :id="canvasId"></canvas>
    </div>
</template>

<script>

//記事末尾で補足
import Chart from 'chart.js/auto';

export default {
  props: ['chartdata','options','canvasId'],
  methods: {
    renderChart() {
      let ctx = document.getElementById(this.canvasId);
      new Chart(ctx, {
        type: 'line',
        data:this.chartdata,
        options: this.options,

      });
    }
  },
  mounted() {
    this.renderChart();
  }
};
</script>