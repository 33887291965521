<template>
  <div class="card text-center">
    <div :class="['card-header', titleBgColorClass]">
      <h3 class="text-white">{{ title }}</h3>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    titleBgColorClass: {
      type: String,
      default: "bg-danger",
    },
    title: {
      type: String,
      require: true,
    },
  },
};
</script>
