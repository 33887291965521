/*
TODO:
*/
import CustomErrorMessage from "../components/CustomErrorMessage.vue";
const errorMessageMixin = {
  components: {
    CustomErrorMessage,
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    handleError(error) {
      if (error.response && [403, 401].includes(error.response.status)) {
        // TODO: use modal to prompt the logout message
        console.log("Do not have the permission, force to logout");
        this.logOut();
      }
      this.message = this.getErrorMessage(error);
    },
    getErrorMessage(error) {
      // eslint-disable-next-line no-debugger
      // debugger;
      // TODO handle login fail error message
      let message = "";
      message =
        (error.response &&
          error.response.data &&
          // validation failed
          (error.response.data.validationError ||
            // login failed
            (error.response.data.non_field_errors &&
              error.response.data.non_field_errors[0]) ||
            error.response.data.detail ||
            error.response.data.message ||
            // create user failed
            error.response.data)) ||
        error.message ||
        error.toString();

      return message;
    },
  },
};

export default errorMessageMixin;
