<template>
  <h3 class="text-center mt-3">パスワード設定</h3>
  <hr />
  <h4>ユーザID :【{{ user.username }}】</h4>
  <Form @submit="onSubmit" class="mt-3">
    <div class="form-group mb-3">
      <label class="form-label">新しいパスワード</label>
      <Field
        name="password"
        class="form-control"
        type="password"
        rules="required"
        @input="detectInputChange"
      ></Field>
      <ErrorMessage name="password1" class="error-feedback" />
      <password-help-text class="mt-2"></password-help-text>
    </div>
    <div class="form-group mb-3">
      <label class="form-label">新しいパスワード（確認用）</label>
      <Field
        name="password2"
        class="form-control"
        type="password"
        rules="required|confirmed:@password"
      ></Field>
      <ErrorMessage name="password2" class="error-feedback">
        パスワードが一致しません。
      </ErrorMessage>
    </div>
    <custom-error-message :message="message"></custom-error-message>
    <div class="row mt-3">
      <div class="text-center">
        <button
          class="btn btn-secondary mx-2"
          style="width: 150px"
          @click="$emit('back')"
        >
          戻る
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          style="width: 150px"
          :disabled="!canSubmit"
        >
          <div v-if="loading">
            <span class="spinner-border spinner-border-sm"></span>
            Loading...
          </div>
          <div v-else>確認</div>
        </button>
      </div>
    </div>
  </Form>
  <!-- <pre>{{ JSON.stringify(user, null, 2) }}</pre> -->
</template>

<script>
import formMixin from "../mixins/formMixin";
import PasswordHelpText from "./extras/PasswordHelpText.vue";
import UserService from "../services/user.service";
export default {
  components: { PasswordHelpText },
  name: "UserChangePassword",
  props: {
    user: Object,
  },
  mixins: [formMixin],
  emits: ["back", "updateSucceed"],
  methods: {
    onSubmit(data) {
      this.beforeSubmit();
      UserService.setPassword(this.user.id, data)
        .then(() => {
          this.$emit("back");
          console.log("change password succeed");
        })
        .catch((error) => {
          this.handleError(error);
          this.loading = false;
        });
    },
  },
};
</script>
