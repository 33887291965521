import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/avg_status/";

class AvgStatusService {
  bulkUpdate(data) {
    /*
      data format
      {
        'name-<pk>': value,
        'start_number-<pk>': value
          .
          .
          .
      }
    */
    return axios.put(API_URL, data, { headers: authHeader() });
  }
}

export default new AvgStatusService();
