import { isEqual } from "lodash/fp";
const userFormMixin = {
  data() {
    let hqOption = this.getHqOption();
    // compute belongingOptions
    let belongingOptions = [];
    let currentUser = this.$store.state.auth.user;
    let facilityOptions = this.getOptions(currentUser.user.all_facilities);
    if (currentUser.user.is_belong_to_hq) {
      belongingOptions = [hqOption].concat(facilityOptions);
    } else {
      belongingOptions = facilityOptions;
    }
    return {
      hqOption,
      belongingOptions,
    };
  },
  methods: {
    getOptions(facilities) {
      return facilities.map((facility) => {
        return {
          name: facility.name,
          id: facility.id,
        };
      });
    },
    getHqOption() {
      return {
        name: "本部",
        is_hq: true,
      };
    },
    onBelongingInput(inputs) {
      // index of latest selected option
      let latestIndex = inputs.length - 1;
      // Remove all other option if hqOption is selected
      if (inputs[latestIndex] == this.hqOption) {
        this.belongingSelected = [this.hqOption];
      } else {
        // Remove hqOption(if existed) if other option is selected
        this.belongingSelected = this.belongingSelected.filter(
          (option) => !isEqual(option, this.hqOption)
        );
      }
    },
    parseUser(user) {
      user["facilities"] = [];
      user["is_belong_to_hq"] = false;
      // eslint-disable-next-line no-debugger
      //   debugger;
      if (isEqual(user.belonging[0], this.hqOption)) {
        user["is_belong_to_hq"] = true;
      } else {
        user["facilities"] = user.belonging.map((facility) => facility.id);
      }
      delete user.belonging;
      user["groups"] = user.groups.map((group) => group.id);
      return user;
    },
  },
};

export default userFormMixin;
