<template>
  <h3>{{ record["date"] }}</h3>
  <chart
     v-if="datacollection"
    :chartdata="datacollection"
    :options="options"
    :canvasId  = "canvasId"
  ></chart>
</template>

<script>
import Chart from "../components/Chart";

export default {
  components: {
    Chart,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    canvasId:{type:Number,required: true},
  },
  data() {
    // parse data
    let ys = [];
    let xs = [];
    let sep = "\n";
    if (this.record.record.includes("\r\n")) {
      sep = "\r\n";
    }
    this.record.record.split(sep).forEach((element) => {
      let tmp = element.split(",");
      ys.push(Number(tmp[1]));
      xs.push(tmp[0]);
    });
    return {
      datacollection: {
        labels: xs,
        datasets: [
          {
            label: "人数の変動",
            data: ys,
            fill: true,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disable bezier curves
          },
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "minute",
                parser: "hh:mm:ss",
              },
            },
          ],
        },
      },
    };
  },
};
</script>
