<template>
  <custom-card title="ステータス設定">
    <Form @submit="handleUpdate">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="table-secondary text-center">
            <tr>
              <th style="min-width: 120px">ステータス名</th>
              <th style="min-width: 80px">下限</th>
              <th style="min-width: 80px">上限</th>
              <th style="min-width: 80px">色</th>
              <th style="min-width: 210px">画像</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(status, index) in statusSet" :key="status.id">
              <td class="align-middle">
                <Field
                  :name="'name' + '-' + status.id"
                  type="text"
                  :value="status.name"
                  class="form-control text-center"
                  @input="detectInputChange"
                  rules="required|maxLength:50"
                />
                <ErrorMessage
                  :name="'name' + '-' + status.id"
                  class="error-feedback"
                />
              </td>
              <td class="align-middle">
                <Field
                  :name="'start_number-' + status.id"
                  type="number"
                  v-model="status.start_number"
                  :rules="{ start_number_rule: status.id }"
                  class="form-control text-center"
                  @input="detectInputChange"
                />
                <ErrorMessage
                  :name="'start_number-' + status.id"
                  class="error-feedback"
                />
              </td>
              <td class="align-middle">
                <input
                  type="text"
                  :value="endNumbers[index]"
                  class="form-control text-center"
                  readonly
                />
              </td>
              <td class="align-middle">
                <div class="d-flex justify-content-center">
                  <Field
                    :name="'color-' + status.id"
                    type="color"
                    :value="status.color"
                    :rules="colorRule"
                    class="form-control form-control-color"
                    @input="detectInputChange"
                  />
                  <ErrorMessage
                    :name="'color-' + status.id"
                    class="error-feedback"
                  />
                </div>
              </td>
              <td class="align-middle text-center">
                現在:
                <a :href="status.image_url">{{
                  url2FileName(status.image_url)
                }}</a>
                <Field
                  :name="'image-' + status.id"
                  type="file"
                  class="form-control"
                  @input="detectInputChange"
                  :key="fileInputKey"
                  rules="image"
                />
                <ErrorMessage
                  :name="'image-' + status.id"
                  class="error-feedback"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <back-or-submit
        :canSubmit="canSubmit"
        :loading="loading"
        :to="{ name: 'location', params: { location_key: locationKey } }"
      ></back-or-submit>
    </Form>
  </custom-card>
  <!-- <pre>{{ JSON.stringify(this.statusSet, null, 2) }}</pre> -->
</template>

<script>
import StatusService from "../services/status.service";
import formMixin from "../mixins/formMixin";
import inputRuleMixin from "../mixins/inputRuleMixin";
import CustomCard from "../components/CustomCard.vue";
import { defineRule } from "vee-validate";

defineRule("start_number_rule", (value, [statusId], ctx) => {
  if (value < 0) {
    return "マイナスの値は無効です。";
  }
  if (!Number.isInteger(parseFloat(value))) {
    return "整数を入力してください。";
  }
  value = parseInt(value);
  statusId = parseInt(statusId);
  if (value - 2 < ctx.form[`start_number-${statusId - 1}`]) {
    return "前のステータスの下限より2大きい必要があります。";
  }

  if (value + 2 > ctx.form[`start_number-${statusId + 1}`]) {
    return "次のステータスの下限より2小さい必要があります。";
  }
  return true;
});
export default {
  name: "GroupStatusSetting",
  props: ["statusSet", "locationKey"],
  emits: ["updateSucceed", "update:statusSet"],
  mixins: [formMixin, inputRuleMixin],
  components: { CustomCard },
  data() {
    return {
      fileInputKey: 0,
    };
  },
  computed: {
    startNumbers() {
      return this.statusSet.map((x) => x.start_number);
    },
    endNumbers() {
      let ret = [];
      this.startNumbers.forEach((element, index) => {
        if (index != 0) ret.push((element - 1).toString());
      });
      ret.push("-");
      return ret;
    },
  },
  methods: {
    setStatusSet(value) {
      this.$emit("update:statusSet", value);
    },
    handleUpdate(data) {
      let formData = new FormData();
      for (const [k, v] of Object.entries(data)) {
        if (k.includes("image")) {
          if (v != undefined) formData.append(k, v[0]);
        } else {
          formData.append(k, v);
        }
      }
      this.beforeSubmit();
      StatusService.bulkUpdate(formData).then(
        (response) => {
          if (response.status == 200) {
            // refresh statusSet with server response.data
            // use v-model, when you need to mutate the data pass frome parent
            // https://v3.vuejs.org/guide/component-basics.html#using-v-model-on-components
            response.data.sort(this.sortStatusSet);
            this.setStatusSet(response.data);
            // set state
            this.clearFormState();
            // change fileInputKey, let vue rerender file input field to clear file input
            // https://stackoverflow.com/a/66348082/11785113
            this.fileInputKey++;
            this.$emit("updateSucceed");
          }
        },
        (error) => {
          this.handleError(error);
        }
      );
    },
    sortStatusSet(a, b){
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
    },
  },
};
</script>
