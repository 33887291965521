import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/location/";

class LocationService {
  getLocation(key) {
    return axios.get(API_URL + key + "/", { headers: authHeader() });
  }
  getStatuses(key) {
    return axios.get(API_URL + key + "/statuses/", { headers: authHeader() });
  }
  updateLocation(key, location) {
    return axios.patch(API_URL + key + "/", location, {
      headers: authHeader(),
    });
  }
}

export default new LocationService();
