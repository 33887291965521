import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/reset_timing/";

class ResetTimingService {
  bulkUpdate(data) {
    return axios.put(API_URL, data, { headers: authHeader() });
  }
}

export default new ResetTimingService();
