class Utils {
  timeStringToDate(timeString) {
    /*
    Convert time string HH:MM:SS to Date object
     */
    let date = new Date();
    let timeStringParts = timeString.split(":");
    date.setHours(timeStringParts[0]);
    date.setMinutes(timeStringParts[1]);
    return date;
  }
  checkIfDuplicateExists(w) {
    return new Set(w).size !== w.length;
  }
  parseBulkUpdateData(data) {
    /*
    Parse bulk update data from
    {
        'fieldA-<id_1>': value,
        'fieldB-<id_1>': value,
        'fieldA-<id_2>': value,
        'fieldB-<id_2>': value,
            .
            .
            .
    }
    Into following array
    [
        {
            'id': <id_1>,
            'fieldA': value,
            'fieldB': value,
                .
                .
                .
        }
        {
            'id': <id_2>,
            'fieldA': value,
            'fieldB': value,
                .
                .
                .
        },
    ]
     */
    let tmp = {};
    let fieldName, id;
    for (const [k, v] of Object.entries(data)) {
      [fieldName, id] = k.split("-");
      if (!(id in tmp)) tmp[id] = {};
      tmp[id][fieldName] = v;
    }
    // add field `id`
    for (const [k, v] of Object.entries(tmp)) {
      v["id"] = parseInt(k);
    }
    return Object.keys(tmp).map((key) => tmp[key]);
  }
}

export default new Utils();
