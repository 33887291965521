<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="facility">
    <div class="container p-1">
      <div class="card text-center">
        <div class="card-header bg-danger">
          <h3 class="text-white">
            {{ "施設［" + facility.name + "］の名称設定" }}
          </h3>
        </div>
        <div class="card-body">
          <Form @submit="handelUpdateFacility">
            <div class="row mb-3">
              <label for="name" class="col-sm-3 col-form-label offset-sm-2">
                名称
              </label>
              <div class="col-sm-5">
                <Field
                  type="text"
                  name="name"
                  class="form-control"
                  :value="facility.name"
                  @input="detectInputChange"
                  rules="required|maxLength:50"
                />
                <ErrorMessage name="name" class="error-feedback" />
              </div>
            </div>
            <custom-error-message :message="message"></custom-error-message>
            <back-or-submit
              :to="{ name: 'facilities' }"
              :canSubmit="canSubmit"
              :loading="loading"
            ></back-or-submit>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHeader from "./CustomHeader.vue";
import FacilityService from "../services/facility.service";
import { Form, Field, ErrorMessage } from "vee-validate";
import formMixin from "../mixins/formMixin";
export default {
  name: "FacilityUpdate",
  mixins: [formMixin],
  components: {
    CustomHeader,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      facility: null,
    };
  },
  computed: {
    titleObjList() {
      return (
        this.facility && [
          {
            title: this.facility.name,
            to: null,
          },
        ]
      );
    },
  },
  beforeMount() {
    FacilityService.getFacility(this.$route.params.facility_key).then(
      (response) => {
        // TODO: deal with empty list
        this.facility = response.data;
      },
      (error) => {
        this.handleError(error);
      }
    );
  },
  methods: {
    // go back to previous level page
    routerBack() {
      this.$router.push({ name: "facilities" });
    },
    handelUpdateFacility(facility) {
      this.beforeSubmit();
      FacilityService.updateFacility(this.facility.key, facility)
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
