<template>
  <div v-if="message" class="alert alert-danger m-2" role="alert">
    <template v-if="typeof message === 'string'"
      ><span class="text-center">{{ message }}</span></template
    >
    <template v-if="typeof message === 'object'">
      <ul>
        <li v-for="(errors, fieldName, index) in message" :key="index">
          {{ fieldName }}
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </li>
      </ul>
    </template>
    <!-- <pre>{{ JSON.stringify(message, null, 2) }}</pre> -->
  </div>
</template>

<script>
export default {
  name: "CustomErrorMessage",
  props: {
    message: {
      required: false,
    },
  },
};
</script>
