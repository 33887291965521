<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="location">
    <div class="container p-1">
      <div class="card text-center">
        <div class="card-header bg-danger">
          <h3 class="text-white">
            {{ "ロケーション［" + location.name + "］の名称設定" }}
          </h3>
        </div>
        <div class="card-body">
          <Form @submit="handelUpdateLocation">
            <div class="row mb-3">
              <label for="name" class="col-sm-3 col-form-label offset-sm-2">
                名称
              </label>
              <div class="col-sm-5">
                <Field
                  type="text"
                  name="name"
                  class="form-control"
                  :value="location.name"
                  @input="detectInputChange"
                  rules="required|maxLength:50"
                />
                <ErrorMessage name="name" class="error-feedback" />
              </div>
            </div>
            <custom-error-message :message="message"></custom-error-message>
            <back-or-submit
              :to="{
                name: 'facility',
                params: { facility_key: location.facility.key },
              }"
              :canSubmit="canSubmit"
              :loading="loading"
            ></back-or-submit>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomHeader from "./CustomHeader.vue";
import LocationService from "../services/location.service";
import { Form, Field, ErrorMessage } from "vee-validate";
import formMixin from "../mixins/formMixin";
export default {
  name: "LocationUpdate",
  mixins: [formMixin],
  components: {
    CustomHeader,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      location: null,
    };
  },
  computed: {
    titleObjList() {
      return (
        this.location && [
          {
            title: this.location.facility.name,
            to: {
              name: "facility",
              params: { facility_key: this.location.facility.key },
            },
          },
          {
            title: this.location.name,
            to: null,
          },
        ]
      );
    },
  },
  beforeMount() {
    // TODO get location
    LocationService.getLocation(this.$route.params.location_key).then(
      (response) => {
        this.location = response.data;
      },
      (error) => {
        this.handleError(error);
      }
    );
  },
  methods: {
    handelUpdateLocation(location) {
      this.beforeSubmit();
      LocationService.updateLocation(this.location.key, location)
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
