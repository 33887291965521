<template>
  <header
    class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
  >
    <a
      class="navbar-brand col-md-3 col-lg-2 me-0 px-3"
      href="#"
      @click="this.$router.go()"
      >ユーザ管理</a
    >
    <router-link
      class="mx-auto h4 nav-link text-white"
      :to="{ name: 'facilities' }"
      >施設一覧へ</router-link
    >
    <button
      class="navbar-toggler d-md-none position-absolute collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </header>
  <div class="container-fluid">
    <div class="row">
      <nav
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
      >
        <div class="position-sticky pt-3">
          <ul class="nav flex-column">
            <li class="nav-item" v-for="(tab, index) in tabs" :key="tab">
              <a
                class="nav-link"
                :class="{ active: currentTabIndex == index }"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                @click="currentTabIndex = index"
              >
                <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                {{ tab.title }}
              </a>
            </li>
            <li class="nav-item">
              <a @click="logOut" class="dropdown-item text-danger">
                <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                ログアウト
              </a>
            </li>
          </ul>

          <h6
            class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
          >
            <span class="mt-3">ログイン情報</span>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-link">ユーザID：{{ currentUser.user.username }}</li>
            <li class="nav-link">ユーザ名：{{ currentUser.user.nickname }}</li>
            <li class="nav-link">
              所属：
              <template v-if="currentUser.user.is_belong_to_hq">本部</template>
              <template v-else>
                <div
                  v-for="facility in currentUser.user.facilities"
                  :key="facility"
                >
                  { {{ facility.name }} }
                </div>
              </template>
            </li>
            <li class="nav-link">
              権限：
              <div v-for="group in currentUser.user.groups" :key="group.id">
                { {{ group.name }} }
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <component :is="tabs[currentTabIndex].name"></component>
        <!-- <pre>{{ JSON.stringify(currentUser, null, 2) }}</pre> -->
      </main>
    </div>
  </div>
</template>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  font-size: 1.1rem;
  top: 0;
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

/* @media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
} */

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  background-color: lightgray;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}
</style>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faList,
  faUser,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UserCreate from "./UserCreate.vue";
import UserList from "./UserList.vue";
library.add([faList, faUser, faSignOutAlt]);
export default {
  name: "UserBase",
  components: {
    FontAwesomeIcon,
    UserCreate,
    UserList,
  },
  data() {
    let tabs = [
      {
        name: "user-list",
        title: "ユーザ一覧",
        icon: "list",
      },
      {
        name: "user-create",
        title: "新規ユーザ作成",
        icon: "user",
      },
    ];
    return {
      tabs,
      currentTabIndex: 0,
    };
  },
};
</script>
