import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/daily_record/";

class DailyRecordService {
  filterDailyRecords(params) {
    /*
      Query list of DailyRecord
      Supported params: group, date, year, month, day
      month: 1~12
    */
    return axios.get(API_URL, { headers: authHeader(), params: params });
  }
}

export default new DailyRecordService();
