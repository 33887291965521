import * as yup from "yup";
const inputRuleMixin = {
  data() {
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    return {
      // validation
      nonNegativeIntegerRule: yup
        .number()
        .integer("整数を入力してください。")
        .required("この項目は必須です。")
        .min(0, "マイナスの値は無効です。"),
      colorRule: yup
        .string()
        .required("この項目は必須です。")
        .length(7)
        .matches(/^#[0-9a-fA-F]{6}$/),
      // how to validate file type input using yup
      // https://github.com/formium/formik/issues/926#issuecomment-430906502
      imageFileRule: yup
        .mixed()
        .test("fileType", "Unsupported File Format", (value) =>
          SUPPORTED_FORMATS.includes(value.type)
        ),
      fileInputKey: 0,
    };
  },
};

export default inputRuleMixin;
