var KinesisVideo = require("aws-sdk/clients/kinesisvideo");
var KinesisVideoSignalingChannels = require("aws-sdk/clients/kinesisvideosignalingchannels");
import adapter from "webrtc-adapter";
let logConfigured = false;
const SignalingClient =
  require("amazon-kinesis-video-streams-webrtc").SignalingClient;

const region = process.env.VUE_APP_AWS_REGION;
const accessKeyId = process.env.VUE_APP_AWS_ACCESS_KEY;
const secretAccessKey = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY;

const kinesisVideoClient = new KinesisVideo({
  region,
  accessKeyId,
  secretAccessKey,
  correctClockSkew: true,
});

function getRandomClientId() {
  return Math.random().toString(36).substring(2).toUpperCase();
}

export const startViewer = async (viewer, channelName, remoteView) => {
  viewer.remoteView = remoteView;
  console.log("browser", adapter.browserDetails.browser);
  console.log("engine", adapter.browserDetails.version);

  const describeSignalingChannelResponse = await kinesisVideoClient
    .describeSignalingChannel({
      ChannelName: channelName, // channel name
    })
    .promise();
  const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
  console.log("[VIEWER] Channel ARN: ", channelARN);

  // Get signaling channel endpoints
  const getSignalingChannelEndpointResponse = await kinesisVideoClient
    .getSignalingChannelEndpoint({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ["WSS", "HTTPS"],
        Role: "VIEWER",
      },
    })
    .promise();
  const endpointsByProtocol =
    getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
      (endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      },
      {}
    );
  console.log("[VIEWER] Endpoints: ", endpointsByProtocol);

  const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels(
    {
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    }
  );

  // Get ICE server configuration
  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({
      ChannelARN: channelARN,
    })
    .promise();
  const iceServers = [];
  iceServers.push({
    urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
  });
  getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
    iceServers.push({
      urls: iceServer.Uris,
      username: iceServer.Username,
      credential: iceServer.Password,
    })
  );
  console.log("[VIEWER] ICE servers: ", iceServers);

  // Create Signaling Client
  viewer.signalingClient = new SignalingClient({
    channelARN,
    channelEndpoint: endpointsByProtocol.WSS,
    clientId: getRandomClientId(),
    role: "VIEWER",
    region: region,
    credentials: {
      // TODO
      //   region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    },
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
  });
  // eslint-disable-next-line no-debugger
  //   debugger;

  viewer.peerConnection = new RTCPeerConnection({
    iceServers,
    iceTransportPolicy: "all",
  });

  viewer.signalingClient.on("open", async () => {
    console.log("[VIEWER] Connected to signaling service");

    // Create an SDP offer to send to the master
    console.log("[VIEWER] Creating SDP offer");
    await viewer.peerConnection.setLocalDescription(
      await viewer.peerConnection.createOffer({
        offerToReceiveVideo: true,
      })
    );
    console.log("[VIEWER] Sending SDP offer");
    viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
    console.log("[VIEWER] Generating ICE candidates");
  });
  viewer.signalingClient.on("sdpAnswer", async (answer) => {
    // Add the SDP answer to the peer connection
    console.log("[VIEWER] Received SDP answer");
    await viewer.peerConnection.setRemoteDescription(answer);
    // Indicate if the connection is successful
    viewer.SDP_RECEIVED = true;
  });

  viewer.signalingClient.on("iceCandidate", (candidate) => {
    // Add the ICE candidate received from the MASTER to the peer connection
    console.log("[VIEWER] Received ICE candidate");
    viewer.peerConnection.addIceCandidate(candidate);
  });

  viewer.signalingClient.on("close", () => {
    console.log("[VIEWER] Disconnected from signaling channel");
  });

  viewer.signalingClient.on("error", (error) => {
    console.error("[VIEWER] Signaling client error: ", error);
  });

  viewer.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
    if (candidate) {
      console.log("[VIEWER] Generated ICE candidate");

      // When trickle ICE is enabled, send the ICE candidates as they are generated.
      console.log("[VIEWER] Sending ICE candidate");
      viewer.signalingClient.sendIceCandidate(candidate);
    } else {
      console.log("[VIEWER] All ICE candidates have been generated");

      // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
      // console.log("[VIEWER] Sending SDP offer");
      // viewer.signalingClient.sendSdpOffer(
      //   viewer.peerConnection.localDescription
      // );
    }
  });
  viewer.peerConnection.addEventListener("track", (event) => {
    console.log("[VIEWER] Received remote track");
    if (remoteView.srcObject) {
      return;
    }
    viewer.remoteStream = event.streams[0];
    remoteView.srcObject = viewer.remoteStream;
  });

  console.log("[VIEWER] Starting viewer connection");
  viewer.signalingClient.open();
};

export const stopViewer = (viewer) => {
  console.log("[VIEWER] Stopping viewer connection");
  if (viewer.signalingClient) {
    viewer.signalingClient.close();
    viewer.signalingClient = null;
  }

  if (viewer.peerConnection) {
    viewer.peerConnection.close();
    viewer.peerConnection = null;
  }

  if (viewer.remoteStream) {
    viewer.remoteStream.getTracks().forEach((track) => track.stop());
    viewer.remoteStream = null;
  }

  if (viewer.remoteView) {
    viewer.remoteView.srcObject = null;
  }
};

export const configureLogging = (logs) => {
  function log(level, messages) {
    const text = messages
      .map((message) => {
        if (typeof message === "object") {
          return JSON.stringify(message, null, 2);
        } else {
          return message;
        }
      })
      .join(" ");
    logs.push({
      time: new Date().toISOString(),
      level: level,
      text: text,
    });
  }
  if (logConfigured) {
    console.error = console._error;
    console.warn = console._warn;
    console.log = console._log;
  }
  logConfigured = true;

  console._error = console.error;
  console.error = function (...rest) {
    log("ERROR", Array.prototype.slice.call(rest));
    console._error.apply(this, rest);
  };

  console._warn = console.warn;
  console.warn = function (...rest) {
    log("WARN", Array.prototype.slice.call(rest));
    console._warn.apply(this, rest);
  };

  console._log = console.log;
  console.log = function (...rest) {
    log("INFO", Array.prototype.slice.call(rest));
    console._log.apply(this, rest);
  };
};
