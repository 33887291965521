<template>
  <div class="row">
    <div class="text-center">
      <button
        type="submit"
        class="btn btn-primary"
        style="width: 150px"
        :disabled="!canSubmit"
      >
        <div v-if="loading">
          <span class="spinner-border spinner-border-sm"></span>
          Loading...
        </div>
        <div v-else>更新</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackOrSubmit",
  props: ["canSubmit", "loading"],
};
</script>
