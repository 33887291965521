import axios from "axios";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/";

class LocationPublicService {
  getLocation(corporation_id, key) {
    return axios.get(
      `${API_URL}public/corp_${corporation_id}/location/${key}/`
    );
  }
  getStatuses(id) {
    return axios.get(`${API_URL}public/location/${id}/statuses/`);
  }
}

export default new LocationPublicService();
