<template>
  <custom-error-message :message="message"></custom-error-message>
  <div class="table-responsive mt-2" v-show="!currentComponent">
    <h3 class="text-center mt-3">ユーザ一覧</h3>
    <hr />
    <table class="table table-bordered table-secondary text-center">
      <thead>
        <tr>
          <th>ユーザID</th>
          <th>ユーザ名</th>
          <th style="min-width:80px">変更</th>
          <th style="min-width:130px">パスワード設定</th>
          <th style="min-width:80px">削除</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user">
          <td class="align-middle">
            <template v-if="user.is_root_user">
              <font-awesome-icon icon="star" class="mx-1"></font-awesome-icon>
            </template>
            <a
              href="#"
              class="text-decoration-none"
              @click="changeComponent('user-detail', user)"
              >{{ user.username }}</a
            >
          </td>
          <td class="align-middle">
            {{ user.nickname }}
          </td>
          <td class="align-middle">
            <button
              href="#"
              class="btn btn-primary"
              :disabled="canNotEdit(user)"
              @click="changeComponent('user-update', user)"
            >
              変更
            </button>
          </td>
          <td class="align-middle">
            <button
              href="#"
              class="btn btn-secondary"
              :disabled="canNotEdit(user)"
              @click="changeComponent('user-change-password', user)"
            >
              設定
            </button>
          </td>
          <td class="align-middle">
            <button
              href="#"
              class="btn btn-danger"
              :disabled="canNotDelete(user)"
              @click="onDeleteButton(user)"
            >
              削除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <component
    v-if="currentComponent"
    :is="currentComponent"
    v-model:user="user"
    @updateSucceed="handleUpdateSucceed"
    @back="currentComponent = null"
  ></component>
  <div class="modal" tabindex="-1" id="deleteConfirmModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">ユーザ削除</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p v-if="user">ユーザーID[ {{ user.username }} ]を削除しますか？</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteUser(user)"
            data-bs-dismiss="modal"
          >
            削除
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre>  {{ JSON.stringify(users, null, 2) }}</pre> -->
</template>
<script>
import UserDetail from "./UserDetail.vue";
import UserUpdate from "./UserUpdate.vue";
import UserChangePassword from "./UserChangePassword.vue";
import UserService from "../services/user.service";
import errorMessageMixin from "../mixins/errorMessageMixin";
import { Modal } from "bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add([faStar]);
export default {
  name: "UserList",
  mixins: [errorMessageMixin],
  components: {
    UserDetail,
    UserUpdate,
    UserChangePassword,
    FontAwesomeIcon,
  },
  data() {
    return {
      users: [],
      currentComponent: null,
      user: null,
    };
  },
  beforeMount() {
    UserService.list()
      .then((response) => {
        this.users = response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  methods: {
    canNotEdit(user) {
      // root user can not be edited
      return user.is_root_user;
    },
    canNotDelete(user) {
      // ineditable user, self user and root user are not deletable
      return (
        this.canNotEdit(user) ||
        user.id == this.currentUser.user.id ||
        user.is_root_user
      );
    },
    handleUpdateSucceed(user) {
      // update this.users
      let index = this.users.findIndex((el) => el.id == user.id);
      this.users[index] = user;
    },
    changeComponent(name, user) {
      this.currentComponent = name;
      this.user = user;
    },
    onDeleteButton(user) {
      this.user = user;
      let modal = new Modal(document.getElementById("deleteConfirmModal"));
      modal.show();
    },
    deleteUser(user) {
      UserService.delete(user.id)
        .then(() => {
          this.users = this.users.filter((el) => el.id != user.id);
          console.log(`delete user(pk=${user.id}) succeed`);
        })
        .catch((error) => {
          this.handleError(error);
          console.log(`delete user(pk=${user.id}) failed`, error);
        });
      // TODO show loading icon before user deletion completed
    },
  },
};
</script>
