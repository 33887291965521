<template>
  <div v-if="status">
    <div class="container p-1">
      <div class="card text-center">
        <div class="card-header" :style="{ backgroundColor: status.color }">
          <h3 class="text-white">
            {{ group.name }}
          </h3>
        </div>
        <custom-error-message v-if="status.image_url == null" message="不適切なステータス設定"></custom-error-message>
        <div class="card-body pt-1 pb-0 px-1">
          <img class="img-fluid" :src="status.image_url" />
          <p class="h5">
            <strong>{{ status.caption }}</strong>
          </p>
          <p>現在の人数：{{ status.count }}</p>
          <div class="d-flex flex-row flex-wrap">
            <router-link
              v-if="canDeviceManagement"
              class="btn btn-primary m-1"
              :to="{ name: 'groupSetting', params: { group_key: group.key } }"
              >設定</router-link
            >
            <button
              class="btn btn-primary m-1"
              type="button"
              data-bs-toggle="button"
              @click="showDailyRecordTable = !showDailyRecordTable"
            >
              日別利用状況
            </button>
            <router-link
              class="btn btn-primary m-1 ms-auto"
              :to="{
                name: 'recentSummary',
                params: {
                  group_key: group.key,
                },
              }"
            >
              過去の利用状況
            </router-link>
            <!-- <a class="btn btn-primary m-1 ms-auto">過去の利用状況</a> -->
          </div>
          <div class="input-group mb-1">
            <button
              class="btn btn-outline-primary"
              type="button"
              @click="startStream"
            >
              リアルタイム映像
            </button>
            <select
              class="form-select"
              v-model="thingName"
              :disabled="showStream"
            >
              <option
                v-for="camera in group.camera_set"
                :key="camera"
                :value="camera.thing_name"
              >
                {{ camera.name }}
              </option>
            </select>
          </div>
          <custom-error-message :message="message"></custom-error-message>
          <div v-if="showDailyRecordTable">
            <hr class="my-2" />
            <daily-record-table :groupId="group.id"></daily-record-table>
          </div>
          <div v-if="showStream">
            <hr class="my-2" />
            <web-rtc-stream
              :tunnelName="thingName"
              @streamStatusUpdated="onStreamStatusUpdated"
            ></web-rtc-stream>
            <button class="btn btn-secondary btn-lg mb-1" @click="stopStream">
              STOP
            </button>
          </div>
        </div>
      </div>
      <!-- <pre>{{ JSON.stringify(group, null, 2) }}</pre> -->
    </div>
  </div>
</template>

<script>
import WebRtcStream from "./WebRtcStream.vue";
import DailyRecordTable from "./DailyRecordTable.vue";
import errorMessageMixin from "../mixins/errorMessageMixin";
export default {
  name: "Group",
  props: ["group", "status"],
  mixins: [errorMessageMixin],
  components: { WebRtcStream, DailyRecordTable },
  data() {
    return {
      thingName: null,
      showStream: false,
      stopStreamTimeoutObj: null,
      showDailyRecordTable: false,
    };
  },
  mounted() {
    if (this.group.camera_set.length != 0) {
      this.thingName = this.group.camera_set[0].thing_name;
    }
  },
  methods: {
    startStream() {
      if (this.thingName == null) {
        return;
      }
      if (this.showStream) {
        this.message = "リアルタイム映像を停止してから操作してください。";
        setTimeout(() => {
          this.message = "";
        }, 3000);
        return;
      }
      this.showStream = true;
      // Auto stopStream after 10 minutes
      this.stopStreamTimeoutObj = setTimeout(this.stopStream, 600e3);
    },
    stopStream() {
      this.showStream = false;
      if (this.stopStreamTimeoutObj) {
        clearTimeout(this.stopStreamTimeoutObj);
      }
    },
    onStreamStatusUpdated(succeed) {
      if (!succeed) {
        this.message = "失敗した、しばらくしてからもう一度実行してください。";
        this.stopStream();
        setTimeout(() => {
          this.message = "";
        }, 3000);
      }
    },
  },
};
</script>
