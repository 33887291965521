<template>
  <custom-header></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div class="container p-1" v-if="show">
    <div class="card text-center">
      <div class="card-header bg-primary">
        <h3 class="text-white">施設一覧</h3>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th style="min-width: 70px">施設名</th>
                <th style="min-width: 140px">ロケーション数</th>
                <th v-if="canEditFacility" style="min-width: 120px">
                  施設名設定
                </th>
              </tr>
            </thead>
            <draggable
              :list="all_facilities"
              tag="tbody"
              handle=".handle"
              @change="onChange"
              item-key="key"
              class="align-middle"
            >
              <template #item="{ element }">
                <tr>
                  <td class="handle">
                    <font-awesome-icon icon="align-justify" />
                  </td>
                  <td>
                    <router-link
                      class="nav-link"
                      :to="{
                        name: 'facility',
                        params: { facility_key: element.key },
                      }"
                      >{{ element.name }}</router-link
                    >
                  </td>
                  <td>{{ element.location_count }}</td>
                  <td v-if="canEditFacility">
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        name: 'facilityUpdate',
                        params: { facility_key: element.key },
                      }"
                      >設定</router-link
                    >
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from "vuedraggable";
import CustomHeader from "./CustomHeader.vue";
import FacilityService from "../services/facility.service";
import UserService from "../services/user.service";
import errorMessageMixin from "../mixins/errorMessageMixin";
library.add(faAlignJustify);
export default {
  name: "Facilities",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
    draggable,
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      all_facilities: null,
      // dont show the order is updated
      show: false,
    };
  },
  computed: {
    canEditFacility() {
      // only user that belong to headquarter and in the device management group can edit facility
      return this.currentUser.user.is_belong_to_hq && this.canDeviceManagement;
    },
  },
  beforeMount() {
    // dont use promise then in a nested way
    FacilityService.getAllFacilities()
      .then(
        // get list of facilities
        (response) => {
          this.all_facilities = response.data;
          this.all_facilities.sort((a, b) => {
            return a.order - b.order;
          });
          this.show = true;
        }
      )
      .catch((error) => {
        this.handleError(error);
      });
  },
  methods: {
    onChange() {
      // update order
      let key2order = new Object();
      this.all_facilities.forEach((element, index) => {
        key2order[element.key] = index;
      });
      UserService.put_order(key2order, "facility").catch((error) => {
        this.handleError(error);
      });
    },
  },
};
</script>
