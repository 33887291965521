<template>
  <div class="row mt-3">
    <div class="text-center">
      <router-link :to="to" class="btn btn-primary mx-2" style="width: 150px"
        >戻る</router-link
      >
      <button
        type="submit"
        class="btn btn-primary"
        style="width: 150px"
        :disabled="!canSubmit"
      >
        <div v-if="loading">
          <span class="spinner-border spinner-border-sm"></span>
          Loading...
        </div>
        <div v-else>更新</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackOrSubmit",
  props: {
    to: {
      type: Object,
      required: false,
    },
    canSubmit: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  // props: ["to", "canSubmit", "loading", "hasBack"],
};
</script>
