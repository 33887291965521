<template>
  <hr class="my-1 mx-1" />
  <div class="container text-center col-md-8">
    <h4>リセット時間設定 - {{ weekdayStrings[weekday] }}</h4>
    <Form @submit="handleUpdate" ref="resetTimingForm">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="table-secondary textcenter">
            <tr>
              <th>No.</th>
              <th>タイミング</th>
              <th>有効/無効</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resetTiming, index) in set" :key="resetTiming.id">
              <td>{{ index + 1 }}</td>
              <td>
                <Field
                  :name="'timing-' + resetTiming.id"
                  type="time"
                  :value="resetTiming.timing"
                  @input="detectInputChange"
                ></Field>
              </td>
              <td>
                <Field
                  name="is_active"
                  type="checkbox"
                  :value="resetTiming.id"
                  @input="detectInputChange"
                >
                </Field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <submit :canSubmit="canSubmit" :loading="loading"></submit>
    </Form>
  </div>
  <!-- <pre>{{ JSON.stringify(this.set, null, 2) }}</pre> -->
</template>

<script>
import formMixin from "../mixins/formMixin";
import Utils from "../assets/js/utils";
import ResetTimingService from "../services/resetTiming.service";
export default {
  name: "GroupResetTimingSetting",
  props: ["set", "weekday"],
  emits: ["updateSucceed", "update:set"],
  mixins: [formMixin],
  mounted() {
    this.setCheckBox();
  },
  updated() {
    this.setCheckBox();
    this.clearFormState();
  },
  data() {
    return {
      timeRe: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
    };
  },
  methods: {
    setSet(value) {
      this.$emit("update:set", value);
    },
    setCheckBox() {
      this.$refs.resetTimingForm.setFieldValue(
        `is_active`,
        this.set.filter((el) => el.is_active).map((el) => el.id)
      );
    },
    convertTimeString(timeString) {
      /*
      convert HH:MM:SS to HH:MM
      */
      // https://stackoverflow.com/a/7536768
      // match for HH:MM:SS or HH:MM
      this.assert(
        this.timeRe.test(timeString),
        `time string(${timeString}) format incorret`
      );
      let sections = timeString.split(":");
      return `${sections[0]}:${sections[1]}`;
    },
    handleUpdate(data) {
      // // eslint-disable-next-line no-debugger
      // debugger;
      this.set.forEach((el) => {
        data[`is_active-${el.id}`] = data["is_active"].includes(el.id)
          ? true
          : false;
      });
      delete data["is_active"];
      let parsedData = Utils.parseBulkUpdateData(data);
      let activeResetTiming = parsedData.filter((el) => el.is_active);
      // validate
      let timingStrings = activeResetTiming.map((el) =>
        this.convertTimeString(el.timing)
      );
      if (Utils.checkIfDuplicateExists(timingStrings)) {
        this.message = "タイミングが重複した。";
        return;
      }
      ResetTimingService.bulkUpdate(parsedData)
        .then((response) => {
          this.setSet(response.data);
          this.clearFormState();
          this.$emit("updateSucceed", response.data, this.$options.name);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
