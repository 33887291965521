<template>
  <h3 class="text-center mt-3">ユーザ変更</h3>
  <hr />
  <h4>ユーザID :【{{ user.username }}】</h4>
  <Form @submit="handleUpdate" class="mt-3">
    <div class="form-group mb-3">
      <label>ユーザ名：</label>
      <Field
        name="nickname"
        class="form-control"
        type="text"
        :value="user.nickname"
        rules="required|maxLength:50"
        @input="detectInputChange"
      />
      <ErrorMessage name="nickname" class="error-feedback" />
    </div>
    <div class="form-group mb-3">
      <label>所属：</label>
      <VueMultiselect
        v-model="belongingSelected"
        :multiple="true"
        :options="belongingOptions"
        label="name"
        track-by="name"
        @update:model-value="onBelongingInput"
      ></VueMultiselect>
      <Field type="hidden" name="belonging" v-model="belongingSelected" />
    </div>
    <div class="form-group mb-3">
      <label>権限：</label>
      <VueMultiselect
        v-model="groupsSelected"
        :multiple="true"
        :options="currentUser.user.all_groups"
        label="name"
        track-by="name"
      ></VueMultiselect>
      <Field type="hidden" name="groups" v-model="groupsSelected" />
    </div>
    <div class="row mt-3">
      <div class="text-center">
        <button
          class="btn btn-secondary mx-2"
          style="width: 150px"
          @click="$emit('back')"
        >
          戻る
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          style="width: 150px"
          :disabled="!canSubmit"
        >
          <div v-if="loading">
            <span class="spinner-border spinner-border-sm"></span>
            Loading...
          </div>
          <div v-else>更新</div>
        </button>
      </div>
    </div>
  </Form>
  <!-- <pre>{{ JSON.stringify(this.belongingSelected, null, 2) }}</pre>
  <pre>{{ JSON.stringify(this.groupsSelected, null, 2) }}</pre> -->
  <!-- <pre>{{ JSON.stringify(this.user) }}</pre> -->
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import formMixin from "../mixins/formMixin";
import userFormMixin from "../mixins/userFormMixin";
import VueMultiselect from "vue-multiselect";
import UserService from "../services/user.service";
export default {
  name: "UserUpdate",
  props: {
    user: Object,
  },
  emits: ["back", "update:user", "updateSucceed"],
  mixins: [formMixin, userFormMixin],
  components: {
    VueMultiselect,
  },
  data() {
    // compute belongingSelected
    let belongingSelected = null;
    if (this.user.is_belong_to_hq) {
      belongingSelected = [this.getHqOption()];
    } else {
      belongingSelected = this.getOptions(this.user.facilities);
    }
    return {
      belongingSelected,
      groupsSelected: this.user.groups,
    };
  },
  watch: {
    belongingSelected() {
      this.detectInputChange();
    },
    groupsSelected() {
      this.detectInputChange();
    },
  },
  methods: {
    setUser(value) {
      this.$emit("update:user", value);
    },
    handleUpdate(user) {
      this.beforeSubmit();
      // parse data
      user = this.parseUser(user);
      UserService.update(this.user.id, user)
        .then((response) => {
          this.setUser(response.data);
          //   response;
          this.clearFormState();
          this.$emit("updateSucceed", response.data);
          this.$emit("back");
          console.log("update succeed");
          // if update a login user, force user to logout and login to refresh user's data
          if (this.user.id == this.currentUser.user.id) {
            console.log("Login user updated");
            this.$store.dispatch("auth/updateUser");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
