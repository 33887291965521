<template>
  <custom-card title="過去の利用状況設定">
    <Form @submit="handleUpdate" ref="groupAvgStatusForm">
      <div class="row mb-2">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          タイトル名
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="recent_summary_title"
            type="text"
            :value="group.recent_summary_title"
            @input="detectInputChange"
            class="form-control text-center"
            rules="required|maxLength:50"
          />
          <ErrorMessage name="recent_summary_title" class="error-feedback" />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          タイトルの帯の色
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="recent_summary_title_color"
            type="color"
            :value="group.recent_summary_title_color"
            :rules="colorRule"
            @input="detectInputChange"
            class="form-control text-center h-100"
          />
          <ErrorMessage
            name="recent_summary_title_color"
            class="error-feedback"
          />
        </div>
      </div>
      <hr class="my-4 mx-1" />
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="table-secondary text-center">
            <tr>
              <th style="min-width: 120px">ステータス名</th>
              <th style="min-width: 80px">説明</th>
              <th style="min-width: 80px">下限</th>
              <th style="min-width: 80px">上限</th>
              <th style="min-width: 210px">画像</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(avgStatus, index) in avgStatusSet" :key="avgStatus.id">
              <td class="align-middle">
                <Field
                  :name="'name' + '-' + avgStatus.id"
                  type="text"
                  :value="avgStatus.name"
                  class="form-control text-center"
                  @input="detectInputChange"
                  rules="required|maxLength:50"
                />
                <ErrorMessage
                  :name="'name' + '-' + avgStatus.id"
                  class="error-feedback"
                />
              </td>
              <td class="align-middle">
                <Field
                  :name="'description' + '-' + avgStatus.id"
                  type="text"
                  :value="avgStatus.description"
                  class="form-control text-center"
                  @input="detectInputChange"
                  rules="required|maxLength:100"
                />
                <ErrorMessage
                  :name="'description' + '-' + avgStatus.id"
                  class="error-feedback"
                />
              </td>
              <td class="align-middle">
                <Field
                  :name="'start_number-' + avgStatus.id"
                  type="number"
                  v-model="avgStatus.start_number"
                  :rules="{
                    avg_start_number_rule: avgStatus.id,
                  }"
                  class="form-control text-center"
                  @input="detectInputChange"
                />
                <ErrorMessage
                  :name="'start_number-' + avgStatus.id"
                  class="error-feedback"
                />
              </td>
              <td class="align-middle">
                <input
                  type="text"
                  :value="endNumbers[index]"
                  class="form-control text-center"
                  readonly
                />
              </td>
             <td class="align-middle text-center">
                現在:
                <a :href="avgStatus.image_url">{{
                  url2FileName(avgStatus.image_url)
                }}</a>
                <Field
                  :name="'image-' + avgStatus.id"
                  type="file"
                  class="form-control"
                  @input="detectInputChange"
                  :key="fileInputKey"
                  rules="image"
                />
                <ErrorMessage
                  :name="'image-' + avgStatus.id"
                  class="error-feedback"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="my-3 mx-1" />
      <div class="row mb-3">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          表示モード
        </label>
        <div class="col-8 col-md-4">
          <div
            v-for="value in 2"
            :key="value"
            class="form-check form-check-inline"
          >
            <Field
              name="recent_summary_mode"
              type="radio"
              :value="value-1"
              @input="detectInputChange"
              class="form-check-input"
            />
            <label class="form-check-label">{{ recent_summary_mode_label[value-1] }}</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          説明文章
        </label>
        <div class="col-8 col-md-4">
          <Field
            name="recent_summary_description"
            type="text"
            :value="group.recent_summary_description"
            @input="detectInputChange"
            class="form-control text-center"
            rules="required|maxLength:200"
          />
          <ErrorMessage
            name="recent_summary_description"
            class="error-feedback"
          />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-4 col-form-label offset-md-3 col-md-2">
          間隔
        </label>
        <!-- 
          <div class="col-8 col-md-4">
          <div
            v-for="value in 4"
            :key="value"
            class="form-check form-check-inline"
          >
            <Field
              name="recent_summary_interval"
              type="radio"
              :value="value"
              @input="detectInputChange"
              class="form-check-input"
            />
            <label class="form-check-label">{{ value }}</label>
          </div>
        -->
        <div class="col-8 col-md-4">
          <div class="container px-10">
            <div class="row g-0">

              <div class="col-md-3">
                <Field class="form-control form-control-sm" 
                ref="recent_summary_interval"
                name="recent_summary_interval" 
                v-model="hour"
                @input="checkHourMin"  
                type="number"
                rules="interval_hour_rule|common_interval_rule:recent_summary_interval_min"
                    />
               </div>
               <div class="col-md-2">
                 <label >時間</label>
               </div>
               <div class="col-md-1">
               </div>
               
               <div class="col-md-3">
                <Field class="form-control form-control-sm" 
                name ="recent_summary_interval_min"
                v-model="min"
                @input="checkHourMin"
                type="number" 
                rules="interval_min_rule|common_interval_rule:recent_summary_interval_min"

                :disabled="minIsDisabled"/>
               </div>

               <div class="col-md-1">
                 <label >分</label>
               </div>
               
              <div class = "row">
                <div class="col-md-5">
                  <ErrorMessage
                        name="recent_summary_interval" 
                        class="error-feedback"
                      />
                 </div>
                 <div class="col-md-5">
                  <ErrorMessage
                        name="recent_summary_interval_min" 
                        class="error-feedback"
                    />
                  </div>

              </div>
       
           </div>
    
          </div>
         </div>
      </div>
      <div class="row mb-2" v-if = "error_flag">
        <label class = "error-feedback">
          0時間0分には設定できません。
        </label>
      </div>
      <back-or-submit
        :canSubmit="canSubmit"
        :loading="loading"
        :to="{ name: 'location', params: { location_key: locationKey } }"
      ></back-or-submit>
    </Form>
  </custom-card>
</template>

<script>
import AvgStatusService from "../services/avgStatus.service";
import GroupService from "../services/group.service";
import formMixin from "../mixins/formMixin";
import inputRuleMixin from "../mixins/inputRuleMixin";
import CustomCard from "./CustomCard.vue";
import { defineRule } from "vee-validate";

defineRule("avg_start_number_rule", (value, [avgStatusId], ctx) => {
  if (value < 0) {
    return "マイナスの値は無効です。";
  }
  if (!Number.isInteger(parseFloat(value))) {
    return "整数を入力してください。";
  }
  value = parseInt(value);
  avgStatusId = parseInt(avgStatusId);
  if (value - 1 < ctx.form[`start_number-${avgStatusId - 1}`]) {
    return "前のステータスの下限より大きい必要があります。";
  }

  if (value + 1 > ctx.form[`start_number-${avgStatusId + 1}`]) {
    return "次のステータスの下限より小さい必要があります。";
  }
  return true;
});

defineRule("interval_hour_rule", (value) => {
  if (value > 24) {
    return "24以下の数字を入力してください。";
  }
  return true;
});

defineRule("interval_min_rule", (value) => {
  if (value > 59) {
    return "59以下の数字を入力してください。";
  }
  return true;
});

defineRule("common_interval_rule", (value) => {
  if (value < 0) {
    return "マイナスの値は無効です。";
  }
  if (!Number.isInteger(parseFloat(value))) {
    return "整数を入力してください。";
  }

  return true;
});

export default {
  name: "GroupAvgStatusSetting",
  props: ["group", "avgStatusSet", "locationKey"],
  emits: ["updateSucceed", "update:avgStatusSet", "update:group"],
  mixins: [formMixin, inputRuleMixin],
  components: { CustomCard },
  data() {
    return {
      fileInputKey: 0,
      hour:this.group.recent_summary_interval,
      min:this.group.recent_summary_interval_min,
      minIsDisabled:false,
      error_flag:false,
      recent_summary_mode_label:["画像", "グラフ"]
    };
  },
  computed: {
    startNumbers() {
      return this.avgStatusSet.map((x) => x.start_number);
    },
    endNumbers() {
      let ret = [];
      this.startNumbers.forEach((element, index) => {
        if (index != 0) ret.push((element-1).toString());
      });
      ret.push("-");
      return ret;
    },
  },
  mounted() {
    console.log("this.group",this.group)
    // use `setFieldValue` instead of setting `checked` property(which did not work)
    this.$refs.groupAvgStatusForm.setFieldValue(
      "recent_summary_mode",
      this.group.recent_summary_mode
    );
    },
  methods: {
    setAvgStatusSet(value) {
      this.$emit("update:avgStatusSet", value);
    },
    setGroup(value) {
      this.$emit("update:group", value);
    },
    handleUpdate(settingInfo) {
      // update Group and bulk update AvgStatus
      if(this.error_flag){
        return
      }
      let groupFormData = new FormData();
      const groupFieldNames = [
        "recent_summary_title",
        "recent_summary_title_color",
        "recent_summary_description",
        "recent_summary_interval",
        "recent_summary_interval_min",
        "recent_summary_mode"
      ];

      groupFieldNames.forEach((el) => {
        groupFormData.append(el, settingInfo[el]);
        delete settingInfo[el];
      });
      let avgStatusFormData = new FormData();
      for (const [k, v] of Object.entries(settingInfo)) {
        if (k.includes("image")) {
          if (v != undefined) avgStatusFormData.append(k, v[0]);
        } else {
          avgStatusFormData.append(k, v);
        }
      }
      this.beforeSubmit();
      AvgStatusService.bulkUpdate(avgStatusFormData)
        .then((response) => {
          if (response.status == 200) {
            response.data.sort(this.sortStatusSet);
            this.setAvgStatusSet(response.data);
            this.fileInputKey++;
            console.log("Update AvgStatus succeed");
            return GroupService.patch(this.group.key, groupFormData);
          }
        })
        .then(
          (response) => {
            if (response.status == 200) {
              
              this.setGroup(response.data);
              this.clearFormState();
              this.$emit("updateSucceed");
              console.log("Patch Group succeed");
            }
          },
          (error) => {
            this.handleError(error);
          }
        );
    },
    
    checkHourMin(){
      if (this.hour == 24){
        this.minIsDisabled = true
        this.min = 0
      }else{
        this.minIsDisabled = false
      }
      if (this.hour == 0 && this.min == 0){
        this.error_flag = true
      }else{
        this.error_flag = false
        this.detectInputChange()
      }
    },
    sortStatusSet(a, b){
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
    },

  },
};
</script>
