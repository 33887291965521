<template>
  <div class="container mt-3">
    <h4>
      ユーザID：
      {{ user.username }}
      <font-awesome-icon
        icon="star"
        v-if="user.is_root_user"
      ></font-awesome-icon>
    </h4>
    <h4>ユーザ名：{{ user.nickname }}</h4>
    <h4>所属：<template v-if="user.is_belong_to_hq">本部</template></h4>
    <template v-if="!user.is_belong_to_hq">
      <div v-for="facility in user.facilities" :key="facility">
        { {{ facility.name }} }
      </div>
    </template>
    <h4 class="mt-1">
      権限：
    </h4>
    <div v-for="group in user.groups" :key="group.id">{ {{ group.name }} }</div>
    <div class="row">
      <button
        class="btn btn-secondary col-6 mt-3 offset-3"
        @click="$emit('back')"
      >
        戻る
      </button>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add([faStar]);
export default {
  name: "UserDetail",
  props: {
    user: Object,
  },
  emits: ["back"],
  components: {
    FontAwesomeIcon,
  },
};
</script>
