import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/opening_period/";

class OpeningPeriodService {
  bulkUpdate(data) {
    return axios.put(API_URL, data, { headers: authHeader() });
  }
}

export default new OpeningPeriodService();
