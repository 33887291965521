<template>
  <custom-card title="メンテナンス状態設定">
    <Form @submit="handleUpdate" ref="groupMaintForm">
      <div class="row mb-2">
        <label class="col-5 col-form-label offset-md-2 col-md-4">
          メンテナンス状態
        </label>
        <div class="d-flex justify-content-center col-7 col-md-4">
          <div class="align-self-center">
            <Field
              name="is_maintaining"
              type="checkbox"
              value="true"
              @input="detectInputChange"
              class="form-check-input"
            />
            <ErrorMessage name="is_maintaining" class="error-feedback" />
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <label
          class="d-flex justify-content-center col-5 col-form-label offset-md-2 col-md-4"
        >
          <span class="align-self-center">メンテナンス状態の画像</span>
        </label>
        <div class="col-7 col-md-4 text-center">
          現在:
          <a :href="group.maintaining_image">{{
            url2FileName(group.maintaining_image)
          }}</a>
          <Field
            name="maintaining_image"
            type="file"
            @input="detectInputChange"
            class="form-control"
            :key="fileInputKey"
            rules="image"
          />
          <ErrorMessage name="maintaining_image" class="error-feedback" />
        </div>
      </div>
      <div class="row">
        <label class="col-5 col-form-label offset-md-2 col-md-4">
          メンテナンス状態の色
        </label>
        <div class="col-7 col-md-4">
          <Field
            name="maintaining_color"
            type="color"
            :value="group.maintaining_color"
            @input="detectInputChange"
            class="form-control text-center h-100"
            :rules="colorRule"
          />
          <ErrorMessage name="maintaining_color" class="error-feedback" />
        </div>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <back-or-submit
        :canSubmit="canSubmit"
        :loading="loading"
        :to="{ name: 'location', params: { location_key: locationKey } }"
      ></back-or-submit>
    </Form>
  </custom-card>
</template>

<script>
import GroupService from "../services/group.service";
import formMixin from "../mixins/formMixin";
import inputRuleMixin from "../mixins/inputRuleMixin";
import CustomCard from "./CustomCard.vue";
export default {
  name: "GroupMaintSetting",
  props: ["group", "locationKey"],
  emits: ["update:group", "updateSucceed"],
  mixins: [formMixin, inputRuleMixin],
  components: {
    CustomCard,
  },
  mounted() {
    this.$refs.groupMaintForm.setFieldValue(
      "is_maintaining",
      this.group.is_maintaining.toString()
    );
  },
  methods: {
    setGroup(value) {
      this.$emit("update:group", value);
    },
    handleUpdate(group) {
      let formData = new FormData();
      formData.append("is_maintaining", group.is_maintaining === "true");
      formData.append("maintaining_color", group.maintaining_color);
      if (group["maintaining_image"] != undefined) {
        formData.append("maintaining_image", group["maintaining_image"][0]);
      }
      this.beforeSubmit();
      GroupService.patch(this.group.key, formData)
        .then((response) => {
          if (response.status == 200) {
            this.setGroup(response.data);
            this.clearFormState();
            this.$emit("updateSucceed");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
