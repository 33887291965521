<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="settingInfo">
    <div class="container p-1">
      <group-basic-setting
        v-model:group="settingInfo.group"
        :locationKey="settingInfo.location.key"
        @updateSucceed="handleUpdateSucceed"
      ></group-basic-setting>
      <group-status-setting
        v-model:statusSet="settingInfo.statusSet"
        :locationKey="settingInfo.location.key"
        @updateSucceed="handleUpdateSucceed"
      >
      </group-status-setting>
      <group-avg-status-setting
        v-model:avgStatusSet="settingInfo.avgStatusSet"
        v-model:group="settingInfo.group"
        :locationKey="settingInfo.location.key"
        @updateSucceed="handleUpdateSucceed"
      >
      </group-avg-status-setting>
      <group-time-setting
        v-model:openingPeriodSet="settingInfo.openingPeriodSet"
        v-model:resetTimingSet="settingInfo.resetTimingSet"
        v-model:group="settingInfo.group"
        :locationKey="settingInfo.location.key"
        @updateSucceed="handleUpdateSucceed"
      ></group-time-setting>
      <group-maint-setting
        v-model:group="settingInfo.group"
        :locationKey="settingInfo.location.key"
        @updateSucceed="handleUpdateSucceed"
      ></group-maint-setting>
      <!-- <pre>{{ JSON.stringify(settingInfo, null, 2) }}</pre> -->
    </div>
  </div>
  <div class="modal" tabindex="-1" id="messageModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">成功</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>設定を更新しました。</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>

<script>
import GroupService from "../services/group.service";
import CustomHeader from "./CustomHeader.vue";
import GroupBasicSetting from "./GroupBasicSetting.vue";
import GroupStatusSetting from "./GroupStatusSetting.vue";
import GroupAvgStatusSetting from "./GroupAvgStatusSetting.vue";
import GroupTimeSetting from "./GroupTimeSetting.vue";
import GroupMaintSetting from "./GroupMaintSetting.vue";
import errorMessageMixin from "../mixins/errorMessageMixin";
import { Modal } from "bootstrap";
export default {
  name: "GroupSetting",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
    GroupBasicSetting,
    GroupStatusSetting,
    GroupAvgStatusSetting,
    GroupTimeSetting,
    GroupMaintSetting,
  },
  data() {
    return {
      settingInfo: null,
    };
  },
  computed: {
    titleObjList() {
      return (
        this.settingInfo && [
          {
            title: this.settingInfo.facility.name,
            to: {
              name: "facility",
              params: { facility_key: this.settingInfo.facility.key },
            },
          },
          {
            title: this.settingInfo.location.name,
            to: {
              name: "location",
              params: { location_key: this.settingInfo.location.key },
            },
          },
          {
            title: this.settingInfo.group.name,
            to: null,
          },
        ]
      );
    },
  },
  beforeMount() {
    GroupService.getSetting(this.$route.params.group_key).then(
      (response) => {
        this.settingInfo = response.data;
        this.settingInfo.statusSet.sort(this.sortStatusSet);
        this.settingInfo.avgStatusSet.sort(this.sortStatusSet);
      },
      (error) => {
        this.handleError(error);
      }
    );
  },
  methods: {
    handleUpdateSucceed() {
      let myModal = new Modal(document.getElementById("messageModal"));
      myModal.show();
    },
    sortStatusSet(a, b){
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
    },
  },
};
</script>
