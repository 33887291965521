<template>
  <div class="video-container">
    <video
      ref="remoteView"
      autoplay
      playsinline
      controls
      style="background-color: #bbff00"
    />
  </div>
  <!-- <div class="card bg-light mb-3">
    <pre class="card-body text-monospace preserve-whitespace text-start">
      <div v-for="log in logs" :key="log.time" >[{{log.time}}] [{{log.level}}] {{log.text}}</div>
    </pre>
  </div> -->
</template>
<style>
.video-container {
  background: black;
  display: inline-block;
  line-height: 0;
  max-width: 100%;
  width: 100%;
}
video {
  width: 100%;
}
</style>
<script>
import { startViewer, stopViewer } from "../assets/js/stream";
export default {
  name: "WebRtcStream",
  props: {
    tunnelName: {
      type: String,
      required: true,
    },
  },
  emits: ["streamStatusUpdated"],
  data() {
    return {
      viewer: null,
      logs: [],
    };
  },
  mounted() {
    this.viewer = {};
    // TODO
    // if (process.env.NODE_ENV === "development") {
    //   configureLogging(this.logs);
    // } else {
    //   setTimeout(() => {
    //     this.$emit("streamStatusUpdated", this.viewer.SDP_RECEIVED);
    //   }, 5e3);
    // }
    // configureLogging(this.logs);
    // setTimeout(this.start, 3000);
    // startViewer(this.viewer, this.tunnelName, this.$refs.remoteView);
    setTimeout(() => {
      this.$emit("streamStatusUpdated", this.viewer.SDP_RECEIVED);
    }, 10e3);
    startViewer(this.viewer, this.tunnelName, this.$refs.remoteView);
  },
  beforeUnmount() {
    stopViewer(this.viewer);
  },
};
</script>
