<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
    :linkObj="linkObj"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="location">
    <date-and-time></date-and-time>
    <!-- TODL: change URL -->
    <qr-code-modal
      :URL="publicLocationUrl"
      :qrCodeSize="300"
      :qrCodeName="location.name"
    ></qr-code-modal>
    <template v-for="(group, index) in group_set" :key="index">
      <group :group="group" :status="status_set[index]" class="my-2"></group>
    </template>
    <!-- <pre>{{ JSON.stringify(location, null, 2) }}</pre>
    <pre>{{ JSON.stringify(group_set, null, 2) }}</pre>
    <pre>{{ JSON.stringify(status_set, null, 2) }}</pre> -->
  </div>
</template>
<script>
import CustomHeader from "./CustomHeader.vue";
import LocationService from "../services/location.service";
import Group from "./Group.vue";
import DateAndTime from "./DateAndTime.vue";
import QrCodeModal from "./QrCodeModal.vue";
import errorMessageMixin from "../mixins/errorMessageMixin";
export default {
  name: "Location",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
    Group,
    DateAndTime,
    QrCodeModal,
  },
  data() {
    return {
      location: null,
      group_set: null,
      status_set: null,
      intervalObj: null,
    };
  },
  computed: {
    publicLocationUrl() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        this.$router.resolve({
          name: "locationPublic",
          params: {
            corporation_id: this.currentUser.user.corporation.id,
            location_key: this.$route.params.location_key,
          },
        }).href
      );
    },
    titleObjList() {
      return (
        this.location && [
          {
            title: this.location.facility.name,
            to: {
              name: "facility",
              params: { facility_key: this.location.facility.key },
            },
          },
          {
            title: this.location.name,
            to: null,
          },
        ]
      );
    },
    linkObj() {
      return (
        this.location && {
          name: "お客様用画面に切り替え",
          to: {
            name: "locationPublic",
            params: {
              corporation_id: this.currentUser.user.corporation.id,
              location_key: this.$route.params.location_key,
            },
          },
        }
      );
    },
  },
  beforeMount() {
    // fetch initial data
    LocationService.getLocation(this.$route.params.location_key)
      .then((response) => {
        this.location = response.data;
        this.group_set = this.location.group_set;
        this.status_set = this.group_set.map((el) => el.status);
      })
      .catch((error) => {
        this.handleError(error);
      });
    // setinterval to update status_set
    let intervalSecond = 5e3;
    let timeoutSecond = 864e5;
    let startTime = new Date().getTime();
    let lastUpdateTime = new Date().getTime();
    this.intervalObj = setInterval(() => {
      // stop updating status_set if after a certain period of time
      if (new Date().getTime() - startTime > timeoutSecond) {
        clearInterval(this.intervalObj);
        return;
      }
      let nowTime = new Date().getTime();
      // only update when window has focus
      if (document.hasFocus() && nowTime - lastUpdateTime > intervalSecond) {
        lastUpdateTime = nowTime;
        this.updateStatusSet();
      }
    }, 200);
  },
  unmounted() {
    clearInterval(this.intervalObj);
  },
  methods: {
    updateStatusSet() {
      LocationService.getStatuses(this.$route.params.location_key)
        .then((response) => {
          this.status_set = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
