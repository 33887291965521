<template>
  <custom-error-message :message="message"></custom-error-message>
  <template v-if="group">
    <custom-header
      :titleObjList="titleObjList"
      :isPublic="isPublic"
    ></custom-header>
    <div class="container ">
      <div class="card text-center">
        <div
          class="card-header"
          :style="{ backgroundColor: group.recent_summary_title_color }"
        >
          <h3 class="text-white">{{ group.recent_summary_title }}</h3>
        </div>

        <div class="card-body pt-1 pb-0 px-2" v-if =  "recent_summary_mode == 0">
          <div class="table table-hover table-responsive ">
            <table class="table m-0">
              <thead>
                <tr>
                  <th class="fixed01"></th>
                  <template 
                  class = "p-1"
                    v-for="x in xs"
                    :key="x">
                  <th class = "align-middle m-0 p-0">
                  <p class="tabletext p-0 my-2 timetick" style="font-weight:bold">
                    {{ x }}
                  </p>
                  </th>
                </template>

                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(weekdayData, index) in weekdayDataList"
                  :key="weekdayData"
                >
                  <td class="align-middle fixed01">
                    <div class="tabletext fw-bold m-0">
                      {{ weekdayStrings[index] }}
                    </div>
                    <small  v-if="weekdayData.openingPeriods.length"
                      >
                      <span class="tabletext m-0 ">利用時間</span>
                      <br />
                      <div class="tabletext" v-for="op in weekdayData.openingPeriods" :key="op">
                        {{ op.start_at.substring(0, 5) }}~{{
                          op.end_at.substring(0, 5)
                        }}
                      </div>
                    </small>
                  </td>
                  <template 
                  class="align-middle p-0"
                    v-for="(section,i) in sectionSet"
                    :key="section"
                    >
                    <td class="align-middle p-0">
                    <template v-if="section_ok[index][i] == 1 ">
                      <div class="text-center">
                        <img
                          class="align-middle stimg"
                          :src="weekdayData.section2value[section]['image_url']"
                          :title="
                            !isPublic
                              ? weekdayData.section2value[section]['value']
                              : ''
                          "
                        />
                      </div>
                    </template>
                    <p class="" v-else>
                      -
                    </p>
                 </td>
                </template>

                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-body pt-1 pb-0 px-2" v-if =  "recent_summary_mode == 1">
          <div class="table-responsive">
            <table class="table m-0">
               <thead>
              </thead>
              <tbody>
                <tr
                  v-for="(weekdayData, index) in weekdayDataList"
                  :key="weekdayData"
                >
                <td class="align-middle p-0">
                    <div class="tabletext fw-bold m-0">
                      {{ weekdayStrings[index] }}
                    </div>
                    <small  v-if="weekdayData.openingPeriods.length"
                      >
                      <span class="tabletext m-0">利用時間</span>
                      <br />
                      <div class="tabletext" v-for="op in weekdayData.openingPeriods" :key="op">
                        {{ op.start_at.substring(0, 5) }}~{{
                          op.end_at.substring(0, 5)
                        }}
                      </div>
                    </small>
                  </td>
                <td  class="align-middle p-0">
                  <bar-chart
                  :ys="ysList[index]"
                  :colorList="colorList[index]"
                  :borderColorList="borderColorList[index]"
                  :xs="this.xs[index]"
                  :idx = "index"
                  :options="options"
                ></bar-chart>
              </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mx-0 mt-2 justify-content-center">
          {{ group.recent_summary_description }}
        </div>
        <hr class="mb-2" />
        <div class="row mx-0 mb-2">
          <template v-for="avgStatus in avgStatusList" :key="avgStatus">
            <div class="mr-3 ml-1 my-1 d-flex">
              <div class="d-flex align-self-baseline align-items-center">
                <div v-if = "recent_summary_mode == 0">
                  <img class=" expimg" :src="avgStatus.image_url" />
                 </div>
                  <div v-else class = "square" v-bind:style="{
                   background  : StatusColor[avgStatus.order]
                }">  </div>
                <div>：</div>
              </div>
              <div class="align-self-center">{{ avgStatus.description }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <pre>{{ JSON.stringify(weekdayDataList, null, 2) }}</pre>
    <pre>{{ JSON.stringify(sectionSet, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(avgStatusSet, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(titleObjList, null, 2) }}</pre> -->
  </template>
</template>
<style scoped>

.blanc{
  text-align: right;
  margin-right: 20%;
}
.timetick{
  position: relative;
	right:50%;
}
.stimg {
  width:80px;
}
.expimg{
  width:50px;
}

.square {
  width: 30px;
  height: 10px;
}
p { font: 80% sans-serif }
.tabletext{
  font-size: medium;
}
.fixed01{
  position: sticky;
  left: 0;
  background: #fff;
}
@media screen and (max-width:480px) {
  .stimg {
  width:60px;
}
.timetick{
  position: relative;
  right:50%;
}
.tabletext{
  font-size: small;
}
}
</style>

<script>
import CustomHeader from "./CustomHeader.vue";
import GroupPublicService from "../services/group-public.service";
import GroupService from "../services/group.service";
import errorMessageMixin from "../mixins/errorMessageMixin";
import BarChart from "../components/BarChart";
import colorLib from '@kurkle/color';

function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export default {
  name: "RecentSummary",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
    BarChart,
  },
  data() {
    return {
      weekdayDataList: null,
      sectionSet: null,
      hourset:[],
      minset:[],
      avgStatusSet: null,
      titleObjList: null,
      group: null,
      recent_summary_mode:0,
      ysList : [[],[],[],[],[],[],[]],
      colorList : [[],[],[],[],[],[],[]],
      borderColorList : [[],[],[],[],[],[],[]],
      xs : [],
      section_ok : [[],[],[],[],[],[],[]]
    };
  },
  computed: {
    isPublic() {
      return !this.$store.state.auth.status.loggedIn;
    },
  },
  beforeMount() {
    // //eslint-disable-next-line no-debugger
    // debugger;

    const p = !this.isPublic
      ? GroupService.getRecentSummary(this.$route.params.group_key)
      : GroupPublicService.getRecentSummary(
          this.$route.params.corporation_id,
          this.$route.params.group_key
        );
    let start_hour;
    let start_min;

    let start =  new Date('August 19, 1975 23:15:30');
    let end =  new Date('August 19, 1975 23:15:30');
    let openingPeriods = [];
    let section_start2end;
    let hit_flag;
    p.then((response) => {
      this.weekdayDataList = response.data.weekdayDataList;
      this.sectionSet = response.data.start_sectionSet;
      section_start2end =  response.data.section_start2end;
      this.StatusColor = response.data.StatusColor
      this.group = response.data.group;
      this.recent_summary_mode = response.data.group.recent_summary_mode
      if (this.recent_summary_mode == 0){
      this.weekdayDataList.forEach(weekdayData =>{

        weekdayData.openingPeriods.forEach(op =>{
          openingPeriods.push(op)
        })
      });
    }
    if (this.recent_summary_mode == 1){
        this.xs = [[],[],[],[],[],[],[]]
    }
      //グラフのデータ作成
      this.sectionSet.forEach((section) => {
        start_hour = Math.floor(Number(section) / 60)
        start_min = Math.floor(Number(section) % 60)
        start.setHours(start_hour,start_min,0);
        if (this.recent_summary_mode == 0){
          
        //x軸のラベル
        this.xs.push(start.toLocaleTimeString().split(':')[0] +":"+start.toLocaleTimeString().split(':')[1])
        }
        this.weekdayDataList.forEach((weekdayData,i) =>{
          if (this.recent_summary_mode == 0){

          hit_flag = 0
            section_start2end[i].forEach((s_e) => {
              if (Number(section) >= s_e[0] && Number(section) < s_e[1]){
                hit_flag = 1
                weekdayData.section2value[section] = {'image_url':weekdayData.section2value[s_e[0]].image_url,
              'value' : weekdayData.section2value[s_e[0]].value,
              'order' :weekdayData.section2value[s_e[0]].order }

                }
              });
    
            if (hit_flag == 1){
              this.section_ok[i].push(1)
            }else{
              this.section_ok[i].push(0);
            }
          }

          if (this.recent_summary_mode == 1 && weekdayData.section2value[section] != undefined){
              section_start2end[i].forEach((s_e) => {
                if (Number(section) == s_e[0]){
                  end.setHours(Math.floor(Number(s_e[1])/60), Math.floor(Number(s_e[1])%60),0);
                  }
                });
              console.log("テスト",end)
              this.xs[i].push(start.toLocaleTimeString().split(':')[0] +":"+start.toLocaleTimeString().split(':')[1] + "~"+end.toLocaleTimeString().split(':')[0] + ":" +end.toLocaleTimeString().split(':')[1]);
              //yの値
              this.ysList[i].push(weekdayData.section2value[section]['value']);
              //バーの色（透明度を付ける）
              this.colorList[i].push(transparentize(this.StatusColor[weekdayData.section2value[section]['order']],0.5));
              //バーの輪郭の色
              this.borderColorList[i].push(this.StatusColor[weekdayData.section2value[section]['order']]);
            }  
        });

      });

      this.avgStatusList = response.data.avgStatusList;
      this.titleObjList = [
        {
          title: response.data.facility.name,
          to: null,
        },
        {
          title: response.data.location.name,
          to: this.isPublic
            ? {
                name: "locationPublic",
                params: {
                  corporation_id: response.data.corporation_id,
                  location_key: response.data.location.key,
                },
              }
            : {
                name: "location",
                params: {
                  location_key: response.data.location.key,
                },
              },
        },
        {
          title: this.group.name,
          to: null,
        },
      ];
    });
  },
};
</script>