<template>
  <h3 class="text-center mt-3">新規ユーザ作成</h3>
  <hr />
  <Form @submit="onSubmit" class="mt-3">
    <div class="form-group mb-3">
      <label class="form-label">ユーザID：</label>
      <Field
        name="username"
        class="form-control"
        type="text"
        rules="required|maxLength:150"
      ></Field>
      <ErrorMessage name="username" class="error-feedback"></ErrorMessage>
    </div>
    <div class="form-group mb-3">
      <label class="form-label">ユーザ名：</label>
      <Field
        name="nickname"
        type="text"
        class="form-control"
        rules="required:maxLength:50"
      ></Field>
      <ErrorMessage name="nickname" class="error-feedback"></ErrorMessage>
    </div>
    <div class="form-group mb-3">
      <label class="form-label">所属：</label>
      <VueMultiselect
        v-model="belongingSelected"
        :multiple="true"
        :options="belongingOptions"
        label="name"
        track-by="name"
        @update:model-value="onBelongingInput"
      ></VueMultiselect>
      <Field type="hidden" name="belonging" v-model="belongingSelected" />
    </div>
    <div class="form-group mb-3">
      <label class="form-label">権限：</label>
      <VueMultiselect
        v-model="groupsSelected"
        :multiple="true"
        :options="currentUser.user.all_groups"
        label="name"
        track-by="name"
      ></VueMultiselect>
      <Field type="hidden" name="groups" v-model="groupsSelected"></Field>
    </div>
    <div class="form-group mb-3">
      <label class="form-label">パスワード</label>
      <Field
        name="password"
        class="form-control"
        type="password"
        rules="required"
      ></Field>
      <password-help-text class="mt-2"></password-help-text>
      <ErrorMessage name="password" class="error-feedback" />
    </div>
    <div class="form-group mb-3">
      <label class="form-label">パスワード（確認用）</label>
      <Field
        name="password1"
        class="form-control"
        type="password"
        rules="required|confirmed:@password"
      ></Field>
      <ErrorMessage name="password1" class="error-feedback">
        <span class="error-feedback">パスワードが一致しません。</span>
      </ErrorMessage>
    </div>
    <custom-error-message :message="message"></custom-error-message>
    <div class="form-group text-center mb-2">
      <button type="submit" class="btn btn-primary" :disabled="loading">
        <div v-if="loading">
          <span class="spinner-border spinner-border-sm"></span>
          Loading...
        </div>
        <div v-else>確認</div>
      </button>
    </div>
  </Form>
  <div class="modal" tabindex="-1" id="successModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">成功</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">新規ユーザ作成しました。</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
button {
  width: 150px;
}
</style>
<script>
import formMixin from "../mixins/formMixin";
import userFormMixin from "../mixins/userFormMixin";
import VueMultiselect from "vue-multiselect";
import UserService from "../services/user.service";
import PasswordHelpText from "./extras/PasswordHelpText.vue";
import { Modal } from "bootstrap";
export default {
  name: "UserCreate",
  mixins: [formMixin, userFormMixin],
  components: { VueMultiselect, PasswordHelpText },
  data() {
    return {
      belongingSelected: [],
      groupsSelected: [],
    };
  },
  methods: {
    onSubmit(user) {
      user = this.parseUser(user);
      this.beforeSubmit();
      UserService.create(user)
        .then((response) => {
          console.log("create user succeed", response.data);
          let modal = new Modal(document.getElementById("successModal"));
          this.clearFormState();
          modal.show();
        })
        .catch((error) => {
          this.handleError(error);
          this.loading = false;
        });
    },
  },
};
</script>
