<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="facility">
    <div class="container p-1">
      <div class="card text-center">
        <div class="card-header bg-primary">
          <h3 class="text-white">［{{ facility.name }}］のロケーション一覧</h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th style="min-width: 130px">ロケーション名</th>
                  <th style="min-width: 80px">カメラ数</th>
                  <th v-if="canDeviceManagement" style="min-width: 180px">
                    ロケーション名称設定
                  </th>
                </tr>
              </thead>
              <tbody class="align-middle">
                <tr v-for="item in facility.location_set" :key="item.key">
                  <td>
                    <router-link
                      class="nav-link"
                      :to="{
                        name: 'location',
                        params: { location_key: item.key },
                      }"
                      >{{ item.name}}</router-link
                    >
                  </td>
                  <td>{{ item.group_count }}</td>
                  <td v-if="canDeviceManagement">
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        name: 'locationUpdate',
                        params: { location_key: item.key },
                      }"
                      >設定</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ JSON.stringify(facility, null, 2) }}</pre> -->
  </div>
</template>

<script>
import CustomHeader from "./CustomHeader.vue";
import FacilityService from "../services/facility.service";
import errorMessageMixin from "../mixins/errorMessageMixin";
export default {
  name: "Facility",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
  },
  data() {
    return {
      facility: null,
    };
  },
  computed: {
    titleObjList() {
      return (
        this.facility && [
          {
            title: this.facility.name,
            to: null,
          },
        ]
      );
    },
  },
  beforeMount() {
    FacilityService.getFacility(this.$route.params.facility_key).then(
      (response) => {
        // TODO: deal with empty list
        this.facility = response.data;
      },
      (error) => {
        this.handleError(error);
      }
    );
  },
};
</script>
