import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/group/";

class GroupService {
  getSetting(key) {
    return axios.get(API_URL + key + "/" + "setting_info/", {
      headers: authHeader(),
    });
  }
  patch(key, group) {
    return axios.patch(API_URL + key + "/", group, { headers: authHeader() });
  }
  getRecentSummary(key) {
    return axios.get(`${API_URL}${key}/recent_summary/`, {
      headers: authHeader(),
    });
  }
}

export default new GroupService();
