<template>

<div class="canvas-container">
      <canvas :id="idx"></canvas>
    </div>
</template>
<style scoped>


</style>

<script>

//記事末尾で補足
import Chart from 'chart.js/auto';

export default {
  props: ['ys','xs','options','idx','colorList','borderColorList','x_offset'],
  methods: {
    renderChart() {
      let ctx = document.getElementById(this.idx);

      new Chart(ctx, {
        type: 'bar',
        data:{
          labels: this.xs,
          datasets: [
            {
              data: this.ys,
              backgroundColor:this.colorList,
              borderColor:this.borderColorList,
              borderWidth: 2,
              borderSkipped: false,
            }
          ]
        },
        options: {
          plugins: {
          legend: {
          display: false,
          },
          tooltip:{
            enabled: false
         },
        },

        responsive: true,
        maintainAspectRatio: false,
        categoryPercentage:0.99,
        barPercentage: 1,
        
        elements: {
 
        },
        layout: {
            padding: {
                left: 30
            }
        },
        scales: {
          y: {
                ticks: {
        display:false
      },
      title: {
          display: true,
          text: '人数',
          font: {
            size: 15,
            weight: 'bold',
            lineHeight: 1.2,
          },
      },
    },
      x:{
        ticks:{
          labelOffset:0
        },
        title: {
          display: true,
          text: '時間',
          font: {
            size: 15,
            weight: 'bold',
            lineHeight: 1.2,
          },
      },
      }
        
        },
        
      },

      });
    }
  },
  mounted() {
    this.renderChart();
  }
};
</script>