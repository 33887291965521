import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
// import and define rules globally
import "./assets/js/validation";
createApp(App)
  .use(router)
  .use(store)
  .mixin({
    data() {
      const weekdayStrings = [
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
        "日曜日",
      ];
      return { weekdayStrings };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
      groupIds() {
        return this.currentUser.user.groups.map((group) => group.id);
      },
      canUserManagement() {
        /*
            User group
            1: user management
            2: device management
            User that in user-management group can access user-management page
            refer to 'people-counter-backend/accounts/fixtures/init_group.json'
        */
        return this.groupIds.includes(1);
      },
      canDeviceManagement() {
        return this.groupIds.includes(2);
      },
    },
    methods: {
      // define global methods
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
      assert(condition, message) {
        if (!condition) {
          throw message ? new Error(message) : new Error("Assertion failed");
        }
      },
      url2FileName(url) {
        let re = /[^/]*\.(png|jpg|jpeg)/g;
        return this.truncatechars(re.exec(url)[0], 19);
      },
      truncatechars(string, len) {
        this.assert(typeof string === "string");
        this.assert(
          typeof len === "number" && len >= 3,
          "len should be bigger than 2"
        );
        return string.length <= len
          ? string
          : string.substring(0, len - 3) + "...";
      },
    },
    created() {
      document.title = "混雑検知AIサービス コンデナーイ";
    },
    beforeMount() {
      /*
      Modify prototypes globally 
      */
      // Monday(0) ~ Sunday(6)
      Date.prototype.getWeekDay = function () {
        return (this.getDay() + 6) % 7;
      };
    },
  })
  .mount("body");
