import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
import Profile from "./components/Profile.vue";
import Facilities from "./components/Facilities.vue";
import FacilityUpdate from "./components/FacilityUpdate.vue";
import Facility from "./components/Facility.vue";
import Location from "./components/Location.vue";
import LocationPublic from "./components/LocationPublic.vue";
import LocationUpdate from "./components/LocationUpdate.vue";
import GroupSetting from "./components/GroupSetting.vue";
import RecentSummary from "./components/RecentSummary.vue";
import UserBase from "./components/UserBase.vue";

const routes = [
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/facilities",
    name: "facilities",
    component: Facilities,
  },
  {
    path: "/facility_:facility_key/",
    name: "facility",
    component: Facility,
  },
  {
    path: "/facility_:facility_key/update",
    name: "facilityUpdate",
    component: FacilityUpdate,
  },
  {
    path: "/location_:location_key/",
    name: "location",
    component: Location,
  },
  {
    path: "/location_:location_key/update",
    name: "locationUpdate",
    component: LocationUpdate,
  },
  {
    path: "/group_:group_key/setting",
    name: "groupSetting",
    component: GroupSetting,
  },
  {
    path: "/group_:group_key/recent_summary",
    name: "recentSummary",
    component: RecentSummary,
  },
  // User management
  {
    path: "/user-management",
    name: "userManagement",
    component: UserBase,
  },
  // public
  {
    path: "/corporation_:corporation_id/location_:location_key",
    name: "locationPublic",
    component: LocationPublic,
  },
  {
    path: "/corporation_:corporation_id/group_:group_key/recent_summary",
    name: "recentSummaryPublic",
    component: RecentSummary,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// check authorized status everytime a navigating action is trigger
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  const publicPages = ["/login"];
  const publicRoute = ["locationPublic", "recentSummaryPublic"];
  const authRequired = !(
    publicPages.includes(to.path) || publicRoute.includes(to.name)
  );
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    return "/login";
  } else {
    return true;
  }
});

export default router;
