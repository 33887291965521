<template>
  <span data-bs-toggle="modal" :data-bs-target="`#${modalId}`">
    <slot></slot>
  </span>
  <div class="modal" tabindex="-1" :id="modalId">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
Usage exaple:
  <custom-modal
    :id="'end_at-' + openingPeriod.id"
    :message="message"
    title="Error"
  >
    <font-awesome-icon icon="exclamation-triangle" />
  </custom-modal>
*/
export default {
  name: "CustomModal",
  props: {
    title: String,
    message: String,
    id: String,
  },
  computed: {
    modalId() {
      return `modal-${this.id}`;
    },
  },
};
</script>
