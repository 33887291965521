<template>
  <hr class="my-1 mx-1" />
  <div class="container text-center col-md-8">
    <h4>利用時間設定 - {{ weekdayStrings[weekday] }}</h4>
    <Form @submit="handleUpdate" ref="openingPeriodForm">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="table-secondary textcenter">
            <tr>
              <th>No.</th>
              <th>開始</th>
              <th>終了</th>
              <th>有効/無効</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(openingPeriod, index) in set" :key="openingPeriod.id">
              <td>{{ index + 1 }}</td>
              <td>
                <Field
                  :name="'start_at-' + openingPeriod.id"
                  type="time"
                  :value="convertTimeString(openingPeriod.start_at)"
                  @input="detectInputChange"
                ></Field>
              </td>
              <td>
                <Field
                  :name="'end_at-' + openingPeriod.id"
                  type="time"
                  :value="convertTimeString(openingPeriod.end_at)"
                  @input="detectInputChange"
                  :rules="
                    `laterThan:@start_at-${openingPeriod.id},終了時間は開始時間より遅くする必要があります。`
                  "
                ></Field>
                <ErrorMessage
                  :name="'end_at-' + openingPeriod.id"
                  v-slot="{ message }"
                >
                  <custom-modal
                    :id="'end_at-' + openingPeriod.id"
                    :message="message"
                    title="Error"
                  >
                    <font-awesome-icon icon="exclamation-triangle" />
                  </custom-modal>
                </ErrorMessage>
              </td>
              <td>
                <Field
                  name="is_active"
                  type="checkbox"
                  :value="openingPeriod.id"
                  @input="detectInputChange"
                >
                </Field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <custom-error-message :message="message"></custom-error-message>
      <submit :canSubmit="canSubmit" :loading="loading"></submit>
    </Form>
  </div>
  <!-- <pre>{{ JSON.stringify(this.set, null, 2) }}</pre> -->
</template>

<script>
import { defineRule } from "vee-validate";
import formMixin from "../mixins/formMixin";
import OpeningPeriodService from "../services/openingPeriod.service";
import Utils from "../assets/js/utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CustomModal from "./CustomModal.vue";
library.add(faExclamationTriangle);

defineRule("laterThan", (timeStringA, [timeStringB, errorMessage]) => {
  let timeA = Utils.timeStringToDate(timeStringA),
    timeB = Utils.timeStringToDate(timeStringB);
  if (!(timeA > timeB)) return errorMessage;
  else return true;
});
export default {
  name: "GroupOpeningPeriodSetting",
  props: {
    set: Array,
    weekday: Number,
  },
  emits: ["updateSucceed", "update:set"],
  mixins: [formMixin],
  components: {
    FontAwesomeIcon,
    CustomModal,
  },
  data() {
    return {
      timeRe: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
    };
  },
  mounted() {
    this.setCheckBox();
  },
  updated() {
    this.setCheckBox();
    this.clearFormState();
  },
  methods: {
    setSet(value) {
      this.$emit("update:set", value);
    },
    setCheckBox() {
      this.$refs.openingPeriodForm.setFieldValue(
        `is_active`,
        this.set.filter((el) => el.is_active).map((el) => el.id)
      );
    },
    convertTimeString(timeString) {
      /*
      convert HH:MM:SS to HH:MM
      */
      // https://stackoverflow.com/a/7536768
      // match for HH:MM:SS or HH:MM
      this.assert(this.timeRe.test(timeString), "time string format incorret");
      let sections = timeString.split(":");
      return `${sections[0]}:${sections[1]}`;
    },
    handleUpdate(data) {
      // parse field `is_active` and clean data
      this.set.forEach((el) => {
        data[`is_active-${el.id}`] = data["is_active"].includes(el.id)
          ? true
          : false;
        // up cast `23:59` to `23:59` at field `end_at`
        if (data[`end_at-${el.id}`] === "23:59")
          data[`end_at-${el.id}`] = "23:59:59";
      });
      delete data["is_active"];
      let parsedData = Utils.parseBulkUpdateData(data);
      let activeOpeningPeriod = parsedData.filter((el) => el.is_active);
      // validate
      activeOpeningPeriod.sort((first, second) => {
        return Utils.timeStringToDate(first["start_at"]) <
          Utils.timeStringToDate(second["start_at"])
          ? -1
          : 1;
      });
      if (activeOpeningPeriod.length > 1) {
        for (let i = 1; i < activeOpeningPeriod.length; i++) {
          if (
            Utils.timeStringToDate(activeOpeningPeriod[i - 1]["end_at"]) >
            Utils.timeStringToDate(activeOpeningPeriod[i]["start_at"])
          ) {
            this.message = "時間帯が重なっています。";
            return;
          }
        }
      }
      // // eslint-disable-next-line no-debugger
      // debugger;
      OpeningPeriodService.bulkUpdate(parsedData)
        .then((response) => {
          if (response.status == 200) {
            this.setSet(response.data);
            this.clearFormState();
            this.$emit("updateSucceed", response.data, this.$options.name);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
