<template>
  <!-- use v-if to prevent error of "Cannot read property xx of null" -->
  <div class="container" v-if="currentUser">
    <router-link to="/profile" class="nav-link">
      <font-awesome-icon icon="user" />
      {{ currentUser.user.username }}
    </router-link>
    <a class="nav-link" @click.prevent="logOut">
      <font-awesome-icon icon="sign-out-alt" /> LogOut
    </a>
    <router-link :to="{ name: 'facilities' }">
      go to facilities
    </router-link>
    <p>
      <strong>Token:</strong>
      {{ currentUser.access_token.substring(0, 20) }} ...
      {{ currentUser.access_token.substr(currentUser.access_token.length - 20) }}
    </p>
    <p>
      <strong>Refresh:</strong>
      {{ currentUser.refresh_token.substring(0, 20) }} ...
      {{ currentUser.refresh_token.substr(currentUser.refresh_token.length - 20) }}
    </p>
    <p>
      <strong>Id:</strong>
      {{ currentUser.user.id }}
    </p>
    <p>
      <strong>All info:</strong>
      <!-- <pre>{{ JSON.stringify(currentUser, null, 2) }}</pre> -->
    </p>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faSignOutAlt);
export default {
  name: "Profile",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    // verify access_token, go to login if it expired
    AuthService.verify_access_token(
      this.$store.state.auth.user.access_token
    ).catch(() => {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    });
  },
};
</script>