import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/user/";

class UserService {
  put_order(key2order, class_name) {
    return axios.put(
      API_URL + "order/",
      {
        key2order: key2order,
        class_name: class_name,
      },
      { headers: authHeader() }
    );
  }
  list() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  update(id, user) {
    return axios.patch(`${API_URL}${id}/`, user, { headers: authHeader() });
  }
  setPassword(id, password) {
    return axios.patch(`${API_URL}${id}/set_password/`, password, {
      headers: authHeader(),
    });
  }
  delete(id) {
    return axios.delete(`${API_URL}${id}/`, { headers: authHeader() });
  }
  create(user) {
    return axios.post(`${API_URL}`, user, { headers: authHeader() });
  }
}

export default new UserService();
