<template>
  <main class="form-signin">
    <Form @submit="handleLogin">
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
      <div class="form-floating">
        <Field
          type="text"
          name="username"
          :class="['form-control']"
          id="floatingInput"
          placeholder="name@example.com"
          rules="required"
        />
        <ErrorMessage name="username" class="error-feedback" />
        <label for="floatingInput">Username</label>
      </div>
      <div class="form-floating">
        <Field
          type="password"
          name="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Password"
          rules="required"
        />
        <label for="floatingPassword">Password</label>
        <ErrorMessage name="password" class="error-feedback" />
      </div>
      <button class="w-100 btn btn-lg btn-primary" type="submit">
        <span v-show="loading" class="spinner-border sinner-border-sm"></span>
        <span>Sign in</span>
      </button>
      <div class="form-group">
        <custom-error-message :message="message"></custom-error-message>
      </div>
    </Form>
  </main>
</template>

<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<script>
/*
vee-validate tutorial
https://vee-validate.logaretm.com/v4/tutorials/basics
*/
import { Form, Field, ErrorMessage } from "vee-validate";
import errorMessageMixin from "../mixins/errorMessageMixin";
export default {
  name: "Login",
  mixins: [errorMessageMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  beforeCreate() {
    // set class of body to apply component speicific global css
    document.body.className = "login text-center";
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/facilities");
    }
  },
  beforeUnmount() {
    // unset class of body when unmount
    document.body.className = "";
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        // todo test
        () => {
          this.$router.push("/facilities");
        },
        (error) => {
          this.loading = false;
          this.handleError(error);
        }
      );
    },
  },
};
</script>
