/*
Promise
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
*/

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/dj-rest-auth/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "login/", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  verify_access_token(token) {
    return axios.post(API_URL + "token/verify/", {
      token: token,
    });
  }
  logout() {
    localStorage.removeItem("user");
  }
  getUser() {
    return axios
      .get(API_URL + "user/", { headers: authHeader() })
      .then((response) => {
        let currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.user = response.data;
        localStorage.setItem("user", JSON.stringify(currentUser));
        return response.data;
      });
  }
}

export default new AuthService();
