<template>
  <nav class="navbar navbar-expand-md navbar-light fixed-top bg-light">
    <div class="container">
      <div class="navbar-brand" href="#">
        <template v-for="(item, index) in titleObjList" :key="item.title">
          <router-link
            v-if="item.to"
            :to="item.to"
            class="text-decoration-none"
            >{{ this.truncatechars(item.title,navTextLength) }}</router-link
          >
          <span v-else>{{ this.truncatechars(item.title,navTextLength) }}</span>
          <span v-if="index != titleObjList.length - 1"> - </span>
        </template>
      </div>
      <template v-if="!isPublic">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <li v-if="linkObj" class="navbar-item">
              <router-link class="nav-link" :to="linkObj.to">{{
                linkObj.name
              }}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                トップ
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'facilities' }"
                    >施設一覧</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <font-awesome-icon icon="user" />
                </span>
                {{ currentUser.user.nickname }}
              </a>
              <ul class="dropdown-menu">
                <li v-if="canUserManagement">
                  <router-link
                    class="dropdown-item bg-primary"
                    :to="{ name: 'userManagement' }"
                    >ユーザ管理</router-link
                  >
                  <!-- <a class="dropdown-item bg-primary">ユーザ管理</a> -->
                </li>
                <li>
                  <a @click="logOut" class="dropdown-item bg-danger"
                    >ログアウト</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUser);
export default {
  name: "CustomHeader",
  /*
  props:
    titleObjList: list of objects， rendered as normal title if `to` set to null
    {
      title: title
      to: {name: router name, params: {}}
    }
    isPublic: if the header is for public
  */
  props: {
    titleObjList: {
      type: Array,
      required: false,
    },
    linkObj: {
      type: Object,
      required: false,
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(){
    return{
      navTextLength:15
    }
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  beforeCreate() {
    document.body.className = "custom_header";
  },
  beforeUnmount() {
    document.body.className = "";
    window.removeEventListener('resize', this.handleResize)

  },
  methods: {
    handleResize: function() {
      // ナビゲーションの文字数判定
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      if(this.width < 360){
      this.navTextLength = 3
      }else if (this.width < 770){
        this.navTextLength = 8
      }else{
        this.navTextLength = 15
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
};
</script>
