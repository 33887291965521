<template>
  <div class="help text-small text-muted">
    <ul>
      <li>あなたの他の個人情報と似ているパスワードにはできません。</li>
      <li>パスワードは最低 8 文字以上必要です。</li>
      <li>よく使われるパスワードにはできません。</li>
      <li>数字だけのパスワードにはできません。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PasswordHelpText",
};
</script>
