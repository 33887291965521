<template>
  <custom-header
    v-if="titleObjList"
    :titleObjList="titleObjList"
    :isPublic="true"
  ></custom-header>
  <custom-error-message :message="message"></custom-error-message>
  <div v-if="location">
    <date-and-time></date-and-time>
    <template v-for="pair in groupStatusPairs" :key="pair">
      <group-public
        :group="pair.group"
        :status="pair.status"
        :corporation_id="location.corporation"
      ></group-public>
    </template>
    <!-- <pre>{{ JSON.stringify(location, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(group_set, null, 2) }}</pre>
    <pre>{{ JSON.stringify(status_set, null, 2) }}</pre> -->
  </div>
</template>
<script>
import CustomHeader from "./CustomHeader.vue";
import LocationPublicService from "../services/location-public.service";
import GroupPublic from "./GroupPublic.vue";
import DateAndTime from "./DateAndTime.vue";
import errorMessageMixin from "../mixins/errorMessageMixin";
export default {
  name: "LocationPublic",
  mixins: [errorMessageMixin],
  components: {
    CustomHeader,
    GroupPublic,
    DateAndTime,
  },
  data() {
    return {
      location: null,
      group_set: null,
      status_set: null,
      intervalObj: null,
    };
  },
  computed: {
    titleObjList() {
      return (
        this.location && [
          {
            title: this.location.facility.name,
            to: null,
          },
          {
            title: this.location.name,
            to: null,
          },
        ]
      );
    },
    groupStatusPairs() {
      return this.group_set.map((group, idx) => {
        return {
          group: group,
          status: this.status_set[idx],
        };
      });
    },
  },
  beforeMount() {
    // fetch initial data
    LocationPublicService.getLocation(
      this.$route.params.corporation_id,
      this.$route.params.location_key
    )
      .then((response) => {
        this.location = response.data;
        this.group_set = this.location.group_set;
        this.status_set = this.group_set.map((el) => el.status);
      })
      .catch((error) => {
        this.handleError(error);
      });
    // setinterval to update status_set
    let intervalSecond = 5e3;
    let timeoutSecond = 864e5;
    let startTime = new Date().getTime();
    let lastUpdateTime = new Date().getTime();
    this.intervalObj = setInterval(() => {
      // stop updating status_set if after a certain period of time
      if (new Date().getTime() - startTime > timeoutSecond) {
        clearInterval(this.intervalObj);
        return;
      }
      let nowTime = new Date().getTime();
      // only update when window has focus
      if (document.hasFocus() && nowTime - lastUpdateTime > intervalSecond) {
        lastUpdateTime = nowTime;
        this.updateStatusSet();
      }
    }, 200);
  },
  unmounted() {
    clearInterval(this.intervalObj);
  },
  methods: {
    updateStatusSet() {
      LocationPublicService.getStatuses(this.location.id)
        .then((response) => {
          this.status_set = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
