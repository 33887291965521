import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_ENDPOINT + "api/facility/";

class FacilityService {
  getAllFacilities() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  getFacility(key) {
    return axios.get(API_URL + key + "/", { headers: authHeader() });
  }
  updateFacility(key, facility) {
    return axios.patch(API_URL + key + "/", facility, {
      headers: authHeader(),
    });
  }
}

export default new FacilityService();
