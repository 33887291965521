<template>
  <div v-if="status">
    <div class="container p-1">
      <div class="card text-center">
        <div class="card-header" :style="{ backgroundColor: status.color }">
          <h3 class="text-white">
            {{ group.name }}
          </h3>
        </div>
        <div class="card-body pt-1 pb-0 px-1">
          <custom-error-message v-if="status.image_url == null" message="不適切なステータス設定"></custom-error-message>
          <img class="img-fluid" :src="status.image_url" />
          <div class="d-flex flex-row flex-wrap">
            <router-link
              class="btn btn-primary m-1 ms-auto"
              :to="{
                name: 'recentSummaryPublic',
                params: {
                  corporation_id: corporation_id,
                  group_key: group.key,
                },
              }"
            >
              過去の利用状況
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- <pre>{{ JSON.stringify(status, null, 2) }}</pre>
    <pre>{{ JSON.stringify(group, null, 2) }}</pre> -->
  </div>
</template>

<style></style>

<script>
import CustomErrorMessage from "./CustomErrorMessage.vue";

export default {
  name: "GroupPublic",
  props: ["group", "status", "corporation_id"],
  components: { CustomErrorMessage },
};
</script>
