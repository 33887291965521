<template>
  <div v-show="!record">
    <div class="d-flex justify-content-center my-2">
      <div>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            v-model="queryYear"
            style="max-width:80px"
            min="2000"
          />
          <span class="input-group-text">年</span>
        </div>
      </div>
      <div class="mx-2">
        <div class="input-group">
          <input
            type="number"
            name="month"
            class="form-control"
            v-model="displayMonth"
            style="max-width:60px"
            min="1"
            max="12"
          />
          <span class="input-group-text">月</span>
        </div>
      </div>
      <button class="btn btn-primary mb-2" @click="updateTable">
        検索
      </button>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <thead class="thead-light">
          <tr>
            <th
              v-for="weekdayString in weekdayStrings"
              :key="weekdayString"
              style="width: 95px"
            >
              {{ weekdayString[0] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- manually set fixed height and width, let all cell have the same heigth and width -->
          <tr v-for="week in weeks" :key="week" style="height: 55px">
            <td class="align-middle" v-for="day in week" :key="day">
              <div v-if="day">
                <div v-if="date2String(day) in date2Record">
                  <button
                    class="btn btn-primary"
                    @click="record = date2Record[date2String(day)]"
                  >
                    {{ date2String(day, (format = "mm/dd")) }}
                  </button>
                </div>
                <div v-else>
                  <span> {{ date2String(day, (format = "mm/dd")) }} </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="record">
    <daily-record :record="record" :canvasId = "groupId"></daily-record>
    <button class="btn btn-secondary mb-1" @click="record = null">
      戻る
    </button>
  </div>
  <!-- <pre>{{ JSON.stringify(records, null, 2) }}</pre> -->
  <!-- <pre>{{ JSON.stringify(date2Record, null, 2) }}</pre> -->
</template>
<script>
import DailyRecordService from "../services/dailyRecord.service";
import errorMessageMixin from "../mixins/errorMessageMixin";
import DailyRecord from "./DailyRecord.vue";
import dateFormat from "dateformat";

export default {
  name: "DailyRecordTable",
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  mixins: [errorMessageMixin],
  components: { DailyRecord },
  data() {
    let now = new Date();
    let nowYear = now.getFullYear();
    let nowMonth = now.getMonth();

    return {
      weeks: [],
      records: [],
      queryMonth: nowMonth,
      queryYear: nowYear,
      showRecord: false,
      record: null,
    };
  },
  computed: {
    date2Record() {
      let date2RecordObj = {};
      this.records.forEach((record) => {
        date2RecordObj[record.date] = record;
      });
      return date2RecordObj;
    },
    displayMonth: {
      get() {
        return this.queryMonth + 1;
      },
      set(newValue) {
        this.queryMonth = newValue - 1;
      },
    },
  },
  beforeMount() {
    this.updateTable();
  },
  methods: {
    getWeeks() {
      /*
        compute weeks according to queryYear and queryMonth
        weeks is an array with length of 5, each element is an array of 7 weekdays
        represented by Date or null (if the Date isn't belong to that month) 
      */
      let firstDay = new Date(this.queryYear, this.queryMonth, 1);
      let lastDay = new Date(this.queryYear, this.queryMonth + 1, 0);
      let days = [];
      let totalDays = this.daysInMonth(this.queryYear, this.queryMonth);
      //  day of the week in Date() comply with: sunday(0) ~ saturday(6)
      for (let i = 0; i < firstDay.getWeekDay(); i++) {
        days.push(null);
      }
      for (let i = 0; i < totalDays; i++) {
        days.push(new Date(this.queryYear, this.queryMonth, i + 1));
      }
      for (let i = 0; i < 6 - lastDay.getWeekDay(); i++) {
        days.push(null);
      }
      let weeks = [];
      for (let i = 0; i < days.length / 7; i++) {
        weeks.push(days.slice(i * 7, (i + 1) * 7));
      }
      return weeks;
    },
    daysInMonth(year, month) {
      /*
        Get total days of month
        Args:
          year: 
          month: 0 ~ 11
      */
      return new Date(year, month + 1, 0).getDate();
    },
    updateTable() {
      /*
        Update this.records and this.weeks given this.queryYear and this.queryMonth
       */
      DailyRecordService.filterDailyRecords({
        group: this.groupId,
        year: this.queryYear,
        month: this.queryMonth + 1,
      })
        .then((response) => {
          this.records = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
      this.weeks = this.getWeeks();
    },
    date2String(date, format = "yyyy-mm-dd") {
      return dateFormat(date, format);
    },
  },
};
</script>
